import React from 'react';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormLabel from './FormLabel';

const Input = ({ onChange, placeholder, value, error, onClick, name }) => (
    <label>
        <input className={"mt-1 form-input block w-full py-2 px-3 " + (error ? "border-red-300 text-red-900 rounded-md shadow-sm placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300" : "border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300") + " transition duration-150 ease-in-out sm:text-sm sm:leading-5"}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            id={name}
            placeholder="DD/MMM/YYYY"
            onClick={onClick}
        />
        <div className="absolute top-0 right-0 px-3 py-2">
            <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
        </div>
    </label>
);
export default function DateBox({ dependentOn, disabled, error, filterDate, name, label, minDate, maxDate, onChange, onClick, value }) {
    return (
        <div className={"col-span-6 sm:col-span-2" + (!dependentOn ? " hidden" : "")}>
            <FormLabel text={label} name={name} color={(error ? "red" : "black")} />
            <div className="z-10 inset-0 overflow-y-auto">

                <DatePicker
                    utcOffset={0}
                    selected={value}
                    onCalendarOpen={onClick}
                    onChange={(event) => {
                        // debugger
                        console.log(event)
                        event = (new Date(event.getTime() - (event.getTimezoneOffset() * 60000)));
                        onChange(event, name)
                    }}
                    showYearDropdown
                    yearDropdownItemNumber={60}
                    scrollableYearDropdown                    
                    showMonthDropdown
                    filterDate={filterDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat="dd/MMM/yyyy"
                    disabled={disabled}
                    customInput={<Input name={name}/>}
                    // wrapperClassName={"mt-1 form-input block w-full py-2 px-3 " + (error ? "border-red-300 text-red-900 rounded-md shadow-sm placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300" : "border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300") + " transition duration-150 ease-in-out sm:text-sm sm:leading-5"}
                />
            </div>

            { error && <p className="mt-2 text-sm text-red-600" id="email-error">{label} must be selected.</p>}
        </div >
    )
}

