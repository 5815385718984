import React, { Component } from 'react'
import SelectBox from '../controls/SelectBox';
import FormSection from '../controls/FormSection';
import TextBox from './../controls/TextBox';

const blankReference = {
    error: {}
}

class ReferenceSection extends Component {
    state = {
        hideActionButton: false,
        currentReference: {},
        references: []
    }

    componentWillMount() {
        if (this.state.references.length == 0) {
            this.addReference()
        }
    }

    componentDidMount() {
        // this.loadDefaults(this.state.courses)
    }

    addReference() {
        const { program } = this.props
        const cReference = { ...blankReference }
        cReference.error = {}
        const references = [...this.state.references, cReference]
        // this.loadDefaults(cReference)

        this.setState({ references: references })
        this.props.onChange(references)
    }

    removeReference(index) {

        var references = [...this.state.references]
        references.splice(index, 1)
        this.setState({ references }, this.props.onChange(references))

    }

    renderActionButtons = (index) => {
        return (
            <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                    {index == 0 ? <button type="button" onClick={() => this.addReference()} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">+</button> :
                        <button type="button" onClick={() => this.removeReference(index)} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">-</button>}
                </span>
            </div>
        )
    }

    handleChange = (e, name) => {
        // if (name) this.state.currentCourse[name] = e
        const { onChange } = this.props
        var currentReference = this.state.currentReference

        let controlName = ""
        if (name) {
            currentReference[name] = e
            controlName = name;
        }
        else {
            currentReference[e.currentTarget.name] = e.currentTarget.value
            controlName = e.currentTarget.name;
        }


        var cReference = { ...currentReference }
        const keys = Object.keys(cReference)
        keys.map(function (key) {
            //  && (value == null || value == 0)
            if (!currentReference) {
                const { currentReference } = this.state
            }
            if (key !== "error") {
                if ((currentReference[key] == null || currentReference[key] == "") && currentReference.error[key] == undefined) return
                if ((currentReference[key] == null || currentReference[key] == "") && currentReference.error[key]) {
                    currentReference.error[key] = true
                    return
                }

                // currentPreference.error[key] = true
            }
        }, this)
        // currentCourse.error = error
        this.setState({ currentReference })
        this.props.onChange(this.state.references)
    }

    setCurrentReference(reference) {
        this.setState({ currentReference: reference })
    }

    renderReference = (reference, index) => {
        // working here need to load courses on campus change
        const { error } = reference
        const { references } = this.props
        let objects = [...references]

        return (
            <>
                <div className={"col-span-6 sm:col-span-6 " + (this.state.hideActionButton ? "hidden" : "")} >
                    <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
                        {
                            <>
                                <h3 className="text-base leading-6 font-medium text-gray-900">Reference #{index + 1}</h3>
                                {this.renderActionButtons(index)}
                            </>
                        }
                    </div>
                </div>
                <TextBox name="InstitutionName" dependentOn={true} error={error.InstituitionName} label="Institution Name" onClick={() => this.setCurrentReference(reference)} value={reference["InstitutionName"]} onChange={this.handleChange} />
                <TextBox name="ContactName" dependentOn={true} error={error.ContactName} label="Contact Name" onClick={() => this.setCurrentReference(reference)} value={reference["ContactName"]} onChange={this.handleChange} />
                <TextBox name="Position" dependentOn={true} error={error.Position} label="Position" onClick={() => this.setCurrentReference(reference)} value={reference["Position"]} onChange={this.handleChange} />
                <TextBox name="ContactNumber" dependentOn={true} error={error.ContactNumber} label="Contact Number" onClick={() => this.setCurrentReference(reference)} value={reference["ContactNumber"]} onChange={this.handleChange} />
                <TextBox name="Email" dependentOn={true} error={error.Email} label="Email" onClick={() => this.setCurrentReference(reference)} value={reference["Email"]} onChange={this.handleChange} />
            </>
        )
    }
    render() {
        const { references } = this.state

        return (
            <FormSection label={this.props.label} >
                {references.length == 0 ? this.renderReference({}, 0) : references.map((reference, index) => this.renderReference(reference, index))}
            </FormSection>
        );
    }
}

export default ReferenceSection;