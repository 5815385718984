import React, { Component } from 'react'
import SelectBox from '../controls/SelectBox';
import FormSection from '../controls/FormSection';

const blankPreference = {
    Campus: null,
    Program: null,
    error: {}
}

class PreferencesSection extends Component {
    state = {
        hideActionButton: false,
        currentCourse: {},
        preferences: []
    }

    componentWillMount() {
        if (this.state.preferences.length == 0) {
            this.addPreference()
        }
    }

    componentDidMount() {
        // this.loadDefaults(this.state.courses)
    }

    loadDefaults(preference) {
        const { campuses, programs } = this.props

        if (campuses.length == 1) preference["Campus"] = campuses[0].campusId
        let objects = [...programs]
        if (parseInt(preference.Campus) > 0) {
            // if (courseType == 9) {

            objects = objects.filter(p => {
                let campusIds = "," + p.campusIds + ","
                if (campusIds.indexOf(",-1,") >= 0 || campusIds.indexOf("," + preference.Campus + ",") >= 0)
                    return p
            })

            // }

        }
        else {
            objects = []
        }
        if (objects.length == 1) preference.Program = objects[0].objectId
    }

    addPreference() {
        const { program } = this.props
        const cPreference = { ...blankPreference }
        cPreference.error = {}
        cPreference.Program = program
        const preferences = [...this.state.preferences, cPreference]
        this.loadDefaults(cPreference)

        this.setState({ preferences: preferences })
        this.props.onChange(preferences)
    }

    removePreference(index) {

        var preferences = [...this.state.preferences]
        preferences.splice(index, 1)
        this.setState({ preferences }, this.props.onChange(preferences))

    }

    renderActionButtons = (index, program) => {
        return (
            <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                    {index == 0 ? <button type="button" onClick={() => this.addPreference()} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">+</button> :
                        <button type="button" onClick={() => this.removePreference(index)} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">-</button>}
                </span>
            </div>
        )
    }

    handleChange = (e, name) => {
        // if (name) this.state.currentCourse[name] = e
        const { onChange, intakes } = this.props
        var currentPreference = this.state.currentPreference

        let controlName = ""
        if (name) {
            currentPreference[name] = e
            controlName = name;
        }
        else {
            currentPreference[e.currentTarget.name] = e.currentTarget.value
            controlName = e.currentTarget.name;
        }

        if (controlName == "Campus") {
            currentPreference.Program = ""
            currentPreference.error = {}
        }

        var cPreference = { ...currentPreference }
        const keys = Object.keys(cPreference)
        keys.map(function (key) {
            //  && (value == null || value == 0)
            if (!currentPreference) {
                const { currentPreference } = this.state
            }
            if (key !== "error") {
                if ((currentPreference[key] == null || currentPreference[key] == "") && currentPreference.error[key] == undefined) return
                if ((currentPreference[key] == null || currentPreference[key] == "") && currentPreference.error[key]) {
                    currentPreference.error[key] = true
                    return
                }

                // currentPreference.error[key] = true
            }
        }, this)
        // currentCourse.error = error
        this.setState({ currentPreference })
        this.props.onChange(this.state.preferences)
    }

    setCurrentPreference(preference) {
        this.setState({ currentPreference: preference })
    }

    renderPreference = (preference, index) => {
        // working here need to load courses on campus change
        const { error } = preference
        const { programs } = this.props
        let objects = [...programs]
        const { campuses } = this.props

        if (parseInt(preference.Campus) > 0) {
            objects = objects.filter(p => {
                let campusIds = "," + p.campusIds + ","
                if (campusIds.indexOf(",-1,") >= 0 || campusIds.indexOf("," + preference.Campus + ",") >= 0)
                    return p
            })
        }
        else {
            objects = []
        }
        objects.map(o => o.objectDetail = o.objectCode.trim() + ' - ' + o.objectName)
        return (
            <>
                <div className={"col-span-6 sm:col-span-6 " + (this.state.hideActionButton ? "hidden" : "")} >
                    <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
                        {
                            <>
                                <h3 className="text-base leading-6 font-medium text-gray-900">Preference #{index + 1}</h3>
                                {this.renderActionButtons(index, preference.Program)}
                            </>
                        }
                    </div>
                </div>
                <SelectBox name="Campus" disabled={campuses.length == 1} label="Campus" idField={"campusId"} valueField={"campusDetail"} error={error.Campus} onClick={() => this.setCurrentPreference(preference)} value={preference["Campus"]} onChange={this.handleChange} data={campuses} />
                <SelectBox disabled={objects.length == 1} error={error.Program} name="Program" label="Program" onClick={() => this.setCurrentPreference(preference)} value={preference["Program"]} onChange={this.handleChange} data={objects} idField="objectId" valueField="objectDetail" />
            </>
        )
    }
    render() {
        const { preferences } = this.state

        return (
            <FormSection label={this.props.label} >
                {preferences.length == 0 ? this.renderPreference({}, 0) : preferences.map((preference, index) => this.renderPreference(preference, index))}
            </FormSection>
        );
    }
}

export default PreferencesSection;