// In this component we have imported React, Programs component from services
// folder, and constants. In this component we have simply created a fragment
// and passed data to the Pograms component.

import React, { Component } from "react";
import Programs from "../services/Programs";
import { CURRENTPROGRAM, PASTPROGRAM } from "../constants";

class Program extends Component {
  render() {
    return (
      <React.Fragment>
        <Programs
          key={"prog" + CURRENTPROGRAM}
          programType={CURRENTPROGRAM}
          title="Current Programs"
          description="Current Programs you are studying in"
        />

        <Programs
          key={"prog" + PASTPROGRAM}
          programType={PASTPROGRAM}
          title="Past Programs"
          description="Past Programs you have already completed"
        />
      </React.Fragment>
    );
  }
}

export default Program;
