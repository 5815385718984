import React, { Component, useState } from 'react';
import FormSection from './controls/FormSection';
import TextBox from './controls/TextBox';
import SelectBox from './controls/SelectBox';
import DateBox from './controls/DateBox';
import Axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment'

import * as constants from "../constants";
import CourseSection from './common/CourseSection';
import AnswerQuiz from './AnswerQuiz';
import { Redirect } from 'react-router-dom';
import AutoComplete from './common/AutoComplete';
import Uploader from './controls/Uploader';
import MultiSelectBox from './controls/MultiSelectBox';
import CheckBox from './controls/CheckBox';
import Program from './Program';
import Modal from './common/Modal';
import PreferencesSection from './common/PreferencesSection';
import ReferenceSection from './common/ReferenceSection';
import RadioBox from './controls/RadioBox';
import FormLabel from './controls/FormLabel';
import TextAreaBox from './controls/TextAreaBox';
import ContactSection from './common/ContactSection';
import SignaturePad from './controls/SignaturePad';
import CheckBoxGroup from './controls/CheckBoxGroup';


class WebForm extends Component {
    state = {
        modalState: false,
        modalMessage: "",
        buttonList: [
            {
                seq: 2,
                buttonClass: "inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                clickFunction: () => this.redirectToThankyou(),
                buttonText: "Ok"
            }
        ],
        webFormCompleted: false,
        completedQuizes: [],
        DropDowns: {},
        webForm: null,
        webFormFields: {
            FirstName: "",
            OSHCArranged: false,
            courses: [],
            documents: [],
            preferences: [],
            references: [],
            contacts: [],
            formSignature: null,
            AlreadyHaveUSI: 2,
            QualificationNotCompleted: 2,
            ApplyForRPL: false,
            ApplyForCreditTransfer: false
        },
        // isPreview: false

    }

    sigPad = {};

    clearSignature = () => {
        if (this.sigPad.clear) {
            const { webFormFields } = this.state
            webFormFields.formSignature = null
            this.setState({ webFormFields: webFormFields })
            this.sigPad.clear()
        }
    }

    trim = () => {
        const { webFormFields } = this.state
        webFormFields.formSignature = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
        // webFormFields.formSignature = this.sigPad.getCanvas()
        this.setState({ webFormFields: webFormFields })
    }

    join(indexName, ...arrays) {
        const map = new Map();
        arrays.forEach((array) => {
            array.forEach((item) => {
                map.set(
                    item[indexName],
                    Object.assign(item, map.get(item[indexName]))
                );
            })
        })
        return [...map.values()];
    }
    async componentDidMount() {

        return Axios
            .get(constants.BASEURL + "WebForms/" + this.props.match.params.id, { CollegeID: constants.COLLEGEID })
            .then((response) => {
                const webForm = response.data
                const { webFormFields } = this.state
                let addressCounter = 0, secondAddressId = 0;
                webFormFields.TLWebFormID = webForm.tlWebFormID;
                webFormFields.documents = webForm.uploadDocuments
                webFormFields.guid = this.props.match.params.id
                webFormFields.applicationType = webForm.formType
                const { programIntakes } = webForm
                var { webFormCampus: webFormCampusTemp, intakes: intakesTemp } = webForm


                // New code to pick webformconfigs (initiated for vce asked by sandeep sir)
                if (window.webformconfigs && window.webformconfigs.defaultWebFormCampus > 0) {
                    // webFormCampus = webFormCampus.filter(c => c.camusId == window.webformconfigs.defaultWebFormCampus)
                    webFormCampusTemp = webFormCampusTemp.filter(c => c.campusId == window.webformconfigs.defaultWebFormCampus)
                }
                else {
                    webFormCampusTemp = webFormCampusTemp;
                }
                if (window.webformconfigs && window.webformconfigs.defaultRollingIntake > 0) {
                    // webFormCampus = webFormCampus.filter(c => c.camusId == window.webformconfigs.defaultWebFormCampus)
                    intakesTemp = intakesTemp.filter(c => c.intakeId == window.webformconfigs.defaultRollingIntake)
                }
                else {
                    intakesTemp = intakesTemp;
                }



                webForm.webFormCampus = webFormCampusTemp
                webForm.intakes = intakesTemp
                const webFormCampus = webFormCampusTemp
                const intakes = intakesTemp


                webFormCampus.map(c => { c.campusDetail = c.campusCode + ' - ' + c.campusName })

                // const webFormIntakes = this.join("intakeId", programIntakes, intakes);
                if (webForm.formType < 3)
                    webForm.programIntakes = programIntakes.map(pi => { return { ...pi, ...intakes.filter(intk => intk.intakeId == pi.intakeId)[0] } });

                // Check for copy address required or not
                const addressSections = webForm.webFormDetail.formSections.filter((n, i) => {
                    if (n.formSectionID == 8 || n.formSectionID == 9) {
                        addressCounter++
                        if (addressCounter == 2 && n.formSectionID == 8) {
                            secondAddressId = n.formSectionID
                            const copyAddressObject = [{
                                "formFieldID": -1,
                                "fieldName": n.formSectionID == 8 ? "CopyResidentialAddress" : "CopyMailingAddress",
                                "fieldLabel": n.formSectionID == 8 ? "Same as residential address" : "Copy Mailing Address",
                                "isMandatory": false,
                                "isFlexible": false,
                                "fieldType": 8,
                                "codeType": 0,
                                "avetCodeType": 0,
                                "fCodeType": 0,
                                "extraLabel": null,
                                "staticData": null
                            }]
                            n.formFields = copyAddressObject.concat(n.formFields)
                        }

                        return n;
                    }
                });
                webForm["totalAddresses"] = addressCounter;
                webForm["targetAddressSectionId"] = secondAddressId;

                this.setState({ webForm, webFormFields }, this.fetchDropDowns(webForm));

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    redirectToThankyou() {

        const { webForm } = this.state
        global.window.location = webForm.thankYouURL
    }
    fetchDropDowns(webform) {
        const { DropDowns } = this.state
        const { formSections } = webform.webFormDetail
        let codeType = 0, apiUrl = ""
        // let controlFound = 0;
        formSections.map(({ formFields }) => {
            // if (!controlFound) {
            formFields.filter(ff => ff.fieldType == 12).map(formField => {
                if (formField.fieldName == "CitizenshipStatus") {
                    if (window.webformconfigs && window.webformconfigs.citizenshipStatusSelectionCount != undefined) {
                        if (window.webformconfigs.citizenshipStatusSelectionCount > 0) {
                            formField.minSelectionCount = window.webformconfigs.citizenshipStatusSelectionCount
                            formField.maxSelectionCount = window.webformconfigs.citizenshipStatusSelectionCount
                        }
                    }
                    else {
                        formField.minSelectionCount = 2
                        formField.maxSelectionCount = 2
                    }
                }
            })
            formFields.filter(ff => ff.fieldType == 2 || ff.fieldType == 6 || ff.fieldType == 7 || ff.fieldType == 10).map(async formfield => {
                codeType = formfield.codeType
                apiUrl = "UserCode/getUserCode?codetype="
                if (formfield.avetCodeType > 0) {
                    apiUrl = "AvetCode/getAvetCode?codeType="
                    codeType = formfield.avetCodeType

                }
                else if (formfield.fCodeType > 0) {
                    apiUrl = "FeeHelpCode/getFeeHelpCode?codeType="
                    codeType = formfield.fCodeType

                }
                if (codeType > 0) {
                    // console.log("Fetching " + formfield.fieldLabel)
                    await Axios.get(constants.BASEURL + apiUrl + codeType, { CollegeID: constants.COLLEGEID })
                        .then((response) => {
                            let type = (formfield.codeType > 0 ? 1 : (formfield.avetCodeType > 0 ? 2 : (formfield.fCodeType > 0 ? 3 : 0)))
                            this.parseCodes(type, response.data)
                            if (parseInt(type) == 2 && parseInt(formfield.avetCodeType) == 2)
                                DropDowns[formfield.fieldName] = response.data.filter(item => item.avetCode != "@")
                            else
                                DropDowns[formfield.fieldName] = response.data
                            this.setState({ DropDowns: DropDowns })
                        })
                        .catch(error => console.log("Error while fetching " + formfield.fieldName, error))
                }
            })
            // }
        })
    }

    parseCodes(type, data) {
        switch (type) {
            case 1:
                if (data[0].value > 0) {
                    data.map(d => {
                        d.idField = d.value
                        d.valueField = d.codeName
                    })
                }
                else {
                    data.map(d => {
                        d.value = d.userCodeId
                        d.idField = d.userCodeId
                        d.valueField = d.codeName
                    })
                }
                break;
            case 2:
                if (data[0].avetCodeType == 2)
                    data = data.filter(item => item.avetCode != "@")

                data.map(d => {
                    d.value = d.avetCodeID
                    d.idField = d.avetCodeID
                    d.valueField = d.avetDescription
                    // d.valueField = d.avetCode + '- ' + d.avetDescription
                })
                break;
            case 3:
                data.map(d => {
                    d.value = d.feeHelpCodeId
                    d.idField = d.feeHelpCodeId
                    d.valueField = d.feeHelpCode + ' - ' + d.feeHelpCodeName
                })
                break;
        }
    }

    resetField = controlName => {
        const { webForm, webFormFields } = this.state
        const { formSections } = webForm.webFormDetail
        let controlFound = 0;
        formSections.map(({ formFields }) => {
            if (!controlFound) {
                formFields.map(formfield => {
                    if (formfield.fieldName == controlName && webFormFields[controlName] && webFormFields[controlName].trim() != "" && formfield.error) {
                        formfield.error = false
                    }
                })
            }
        })
        this.setState({ webForm: webForm })
    }

    resetMandatory = (e, name) => {
        const { webForm, webFormFields } = this.state
        const { formSections } = webForm.webFormDetail
        let controlFound = false;
        formSections.map(({ formFields }) => {
            if (!controlFound) {
                formFields.map(formfield => {
                    if (formfield.fieldName == name) {
                        formfield.isMandatory = e.currentTarget.checked
                    }
                })
            }
        })
    }
    handleAchievementsChange = (e, dataRow) => {
        const { DropDowns, webFormFields } = this.state
        dataRow[e.currentTarget.name] = e.currentTarget.type === "checkbox" ? e.currentTarget.checked : (e.currentTarget.type === "select-one" ? e.currentTarget.value : e.currentTarget.value)
        if (webFormFields.HasPriorAchievments && DropDowns["PriorAchievments"]) {
            const achievements = DropDowns["PriorAchievments"]
            webFormFields.PriorAchievments = ""
            achievements.forEach(ach => {
                if (ach.chkachievement) {
                    if (webFormFields.PriorAchievments != "") webFormFields.PriorAchievments += ","
                    webFormFields.PriorAchievments += ach.avetCodeID
                    if (ach.selachievement) webFormFields.PriorAchievments += "|" + ach.selachievement
                }
            })
        }
        this.setState({ DropDowns, webFormFields })
    }
    handleCheckBoxGroup = (e, dataRow) => {
        const { webFormFields } = this.state
        let currentSelection = webFormFields[e.currentTarget.name] || ""

        //  = e.currentTarget.type === "checkbox" ? e.currentTarget.checked : (e.currentTarget.type === "select-one" ? e.currentTarget.value : e.currentTarget.value)
        if (e.currentTarget.checked) {
            if (currentSelection != "") currentSelection += ","
            currentSelection += e.currentTarget.value
        }
        else {
            // if(currentSelection.indexOf(",") >= 0){
            var values = currentSelection.split(",");
            currentSelection = values.filter(val => {
                return val != e.currentTarget.value
            }).join(",")
            // }
            // else{
            //     if(values == e.currentTarget.value)
            //         currentSelection = ""
            // }
        }

        webFormFields[e.currentTarget.name] = currentSelection
        this.setState({ webFormFields })
    }
    handleChange = (e, name) => {
        const { webForm, webFormFields } = this.state
        const { formSections } = webForm.webFormDetail
        // debugger
        let controlFound = false;

        let controlName = ""
        if (name) {
            let orgName = name.action ? name.name : name
            if (e.constructor == (new Date()).constructor) {
                if (window.event.target.className.indexOf("react-datepicker") >= 0)
                    webFormFields[orgName] = e
                else {
                    let tmpDate = window.event.target.value;
                    if (tmpDate.length == 11) {
                        // let tmpDate = moment(window.event.target.value,"dd/MMM/yyyy", true);  
                        debugger
                        if (moment(tmpDate, "DD/MMM/YYYY", true).isValid())
                            webFormFields[orgName] = e
                    }
                }
            }
            else
                webFormFields[orgName] = e
            controlName = name;
        }
        else {

            webFormFields[e.currentTarget.name] = e.currentTarget.type === "checkbox" ? e.currentTarget.checked : (e.currentTarget.type === "select-one" ? parseInt(e.currentTarget.value) : (e.currentTarget.type === "radio" ? JSON.parse(e.currentTarget.value) : e.currentTarget.value))
            controlName = e.currentTarget.name;
            if (controlName == "ReferredByAgent") {
                this.resetMandatory(e, "AgentId")
            }
            else if (controlName == "USIPermission") {
                this.resetMandatory(e, "UniqueStudentIdentifier")
            }
            // else if (controlName == "CopyMailingAddress") {
            //     this.copyAddress(e, 1)
            // }            
            else if (controlName == "CopyResidentialAddress") {
                this.copyAddress(e, 2)
            }
            else if (controlName == "AlreadyHaveUSI") {
                if (webFormFields["AlreadyHaveUSI"]) {
                    if (JSON.parse(webFormFields["AlreadyHaveUSI"]) == 1) {
                        webFormFields["CollegeSetUpUSI"] = undefined;
                        webFormFields["TownOfBirth"] = undefined;
                        webFormFields["BirthCountry"] = undefined;
                    }
                    else {
                        webFormFields["USIPermission"] = undefined;
                        this.resetMandatory(e, "UniqueStudentIdentifier")
                        webFormFields["UniqueStudentIdentifier"] = undefined;
                    }
                }
            }

        }
        formSections.map(({ formFields }) => {
            if (!controlFound) {
                formFields.map(formfield => {
                    if (formfield.fieldName == controlName && webFormFields[controlName]) {
                        if (webFormFields[controlName].constructor == String().constructor && webFormFields[controlName].trim() != "" && formfield.error) {
                            formfield.error = false
                        }

                        if (webFormFields[controlName].constructor == new Date().constructor && formfield.error) {
                            formfield.error = false
                        }

                        if (webFormFields[controlName].constructor == new Number().constructor && formfield.error) {
                            formfield.error = false
                        }
                    }
                })
            }
        })
        this.setState({ webFormFields: webFormFields, webForm: webForm })

    }

    copyAddress = (e, type) => {
        const { webFormFields } = this.state
        if (e.currentTarget.checked) {
            // if(type==1){
            // var LocalAddressLine2 = "";
            // if(jsTrim(webFormFields["ProperyName"]) != ""){
            //     $("#LocalAddressLine1").val(jsTrim($("#PropertyNameSource").val()));
            //     LocalAddressLine2 = jsTrim($("#FlatDetailSource").val())
            //     if(jsTrim($("#StreetNoSource").val()) != ""){
            //         if(LocalAddressLine2 != "")
            //             LocalAddressLine2 += "/";
            //         LocalAddressLine2 += jsTrim($("#StreetNoSource").val())				
            //     }
            //     if(LocalAddressLine2 != "")
            //         LocalAddressLine2 += " ";
            //     LocalAddressLine2 += jsTrim($("#StreetNameSource").val());
            //     $("#LocalAddressLine2").val(LocalAddressLine2);
            // }
            // else{
            //     LocalAddressLine2 = jsTrim($("#FlatDetailSource").val());
            //     if(jsTrim($("#StreetNoSource").val()) != ""){
            //         if(LocalAddressLine2 != "")
            //             LocalAddressLine2 += "/";
            //         LocalAddressLine2 += jsTrim($("#StreetNoSource").val());
            //     }
            //     if(LocalAddressLine2 != "")
            //         LocalAddressLine2 += " ";
            //     LocalAddressLine2 += jsTrim($("#StreetNameSource").val())
            //     $("#LocalAddressLine1").val(LocalAddressLine2);
            //     $("#LocalAddressLine2").val("");
            // }
            let PropertyName = webFormFields["PropertyName"] ?? ""
            let StreetNo = webFormFields["StreetNo"] ?? ""
            var localaddress2 = ""
            if (PropertyName && PropertyName.trim() != "") {
                webFormFields["MailingAddressLine1"] = webFormFields["PropertyName"] ?? ""
                localaddress2 = webFormFields["FlatDetail"] ?? ""

                if (StreetNo.trim() != "") {
                    if (localaddress2.trim() != "")
                        localaddress2 += "/"
                    localaddress2 += StreetNo.trim()
                }

                if (localaddress2.trim() != "")
                    localaddress2 += " "

                localaddress2 += webFormFields["StreetName"] ?? ""

                webFormFields["MailingAddressLine2"] = localaddress2

            }
            else {
                localaddress2 = webFormFields["FlatDetail"] ?? ""
                if (StreetNo.trim() != "") {
                    if (localaddress2 != "")
                        localaddress2 += "/"

                    localaddress2 += StreetNo.trim()

                    if (localaddress2.trim() != "")
                        localaddress2 += " "

                    localaddress2 += webFormFields["StreetName"] ?? ""
                    webFormFields["MailingAddressLine1"] = localaddress2
                }
                // webFormFields["MailingAddressLine2"] = ""
            }
            this.setState({ selectedMailingSuburbID: webFormFields["selectedAvetmissSuburbID"] })
            webFormFields["MailingSuburbID"] = webFormFields["AvetmissSuburbID"] ?? ""
            webFormFields["MailingSuburbIDState"] = webFormFields["AvetmissSuburbIDState"] ?? ""
            // webFormFields["AvetmissSuburbIDState"] = webFormFields["AvetmissSuburbID"] && ""
            webFormFields["MailingSuburbIDPostCode"] = webFormFields["AvetmissSuburbIDPostCode"] ?? ""
            webFormFields["MailingSuburbIDCountry"] = webFormFields["AvetmissSuburbIDCountry"] ?? ""
            // }
            // else {

            // }
            this.setState({ webFormFields: webFormFields })
        }

    }

    handleSuburb = (fieldName, selectedSuburb) => {
        const { webFormFields } = this.state
        if (window.webformconfigs && window.webformconfigs.showStateAbbr)
            webFormFields[fieldName + "State"] = selectedSuburb.stateAbbr || ""
        else
            webFormFields[fieldName + "State"] = selectedSuburb.state || ""
        webFormFields[fieldName + "PostCode"] = selectedSuburb.postCode || ""
        webFormFields[fieldName + "Country"] = selectedSuburb.country || ""
        webFormFields[fieldName] = selectedSuburb.suburbID
        webFormFields["selected" + fieldName] = selectedSuburb
        this.setState({ webFormFields: webFormFields })
    }
    handleAgent = (fieldName, selectedAgent) => {
        const { webFormFields } = this.state
        webFormFields[fieldName] = selectedAgent.agentID
        this.setState({ webFormFields: webFormFields })
    }

    renderField(formField) {
        const { webFormFields, DropDowns, webForm } = this.state
        const { fieldType, fieldLabel, fieldName, error, isMandatory, extraLabel, staticData } = formField

        let dependentOn = ""
        // if (webFormFields[fieldName] && webFormFields[fieldName].trim() != "") formField.error = false
        dependentOn = true
        if (fieldName == "IELTSTestLocation" || fieldName == "IELTSTestScore" || fieldName == "IELTSTestDate") {
            dependentOn = webFormFields["IELTSTestCurrent"] //&& webFormFields["EnglishFirstLanguage"]
        }
        else if (fieldName == "EnglishPriorCourseProvider" || fieldName == "EnglishPriorCourseName") {
            dependentOn = webFormFields["EnglishPriorCourse"]
        }
        else if (fieldName == "EnglishAssessmentTestMethod" || fieldName == "EnglishAssessmentTestScore" || fieldName == "EnglishAssessmentTestDate") {
            dependentOn = webFormFields["EnglishAssessmentTestCompleted"]
        }
        else if (fieldName == "HomeStayRequestType" || fieldName == "HomeStayRequestDuration") {
            dependentOn = webFormFields["HomeStayRequested"]
        }
        else if (fieldName == "NameOSHCInsurer" || fieldName == "MemberNumber") {
            dependentOn = webFormFields["OSHCArranged"]
        }
        else if (fieldName == "NameOSHCCoverDates" || fieldName == "NameOSHCCoverType") {
            dependentOn = !webFormFields["OSHCArranged"]
        }

        switch (fieldType) {
            case 1:
                if (fieldName == "UniqueStudentIdentifier") {
                    if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
                        dependentOn = webFormFields["USIPermission"]
                    }
                    else {
                        dependentOn = webFormFields["USIPermission"] && webFormFields["AlreadyHaveUSI"] && JSON.parse(webFormFields["USIPermission"]) == 1 && JSON.parse(webFormFields["AlreadyHaveUSI"]) == 1
                    }
                    formField.isMandatory = dependentOn
                    formField.error = false

                }
                else if (fieldName == "LastSchoolYear") {
                    let schoolLevel = webFormFields["SchoolLevelCompleted"]
                    const schoolLevelDD = DropDowns["SchoolLevelCompleted"]
                    if (webFormFields["SchoolLevelCompleted"] && webFormFields["SchoolLevelCompleted"] > 0) {
                        const selectedSLD = schoolLevelDD.filter(sld => sld.avetCodeID == webFormFields["SchoolLevelCompleted"])
                        if (selectedSLD[0].avetCode == "02" || selectedSLD[0].avetCode == "@@") {
                            formField.isMandatory = false
                            formField.error = false
                        }
                        else {
                            formField.isMandatory = true
                            formField.error = false
                        }
                    }
                    else {
                        formField.isMandatory = false
                        formField.error = false
                    }
                }
                else if (fieldName == "DIBPCity" || fieldName == "DIBPCountry") {
                    if (webFormFields["VisaType"] && ((parseInt(webFormFields["VisaType"]) >= 1 && parseInt(webFormFields["VisaType"]) <= 7) || parseInt(webFormFields["VisaType"]) == 18 || parseInt(webFormFields["VisaType"]) == 25)) {

                    }
                    else {
                        dependentOn = false
                    }
                }
                else if (fieldName == "PriorVisaRefusalNotes") {
                    dependentOn = webFormFields["PriorVisaRefusal"]
                }
                else if (fieldName == "TownOfBirth") {
                    if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
                        dependentOn = webFormFields["CollegeSetUpUSI"]
                    }
                    else {
                        dependentOn = webFormFields["CollegeSetUpUSI"] && webFormFields["AlreadyHaveUSI"] && JSON.parse(webFormFields["CollegeSetUpUSI"]) == 1 && JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2
                    }
                    formField.isMandatory = dependentOn
                    formField.error = false
                }
                else if (fieldName == "EATCName") {
                    dependentOn = webFormFields["IsCompletedEATC"]
                    formField.isMandatory = dependentOn
                    formField.error = false
                }
                else if (fieldName == "TaxFileNo") {
                    // dependentOn = webFormFields["HasTaxFileNo"]
                    dependentOn = false
                    formField.isMandatory = dependentOn
                    formField.error = false
                }

                // else if (fieldName == "WebVisaType") {
                //     dependentOn = parseInt(webFormFields["CitizenshipStatus"]) == 3 ? true : false
                //     formField.isMandatory = dependentOn
                // }                

                return <TextBox dependentOn={dependentOn} name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 2:
                // console.log(fieldName)
                if (fieldName == "TypeOfApprenticeship") {
                    dependentOn = webFormFields["RegisterinApprenticeship"]
                    formField.isMandatory = dependentOn
                    formField.error = false
                }
                else if (fieldName == "AvetmissSchoolType") {
                    dependentOn = webFormFields["AtSchool"]
                    formField.isMandatory = dependentOn
                    formField.error = false
                }
                else if (fieldName == "BirthCountry") {
                    if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
                        dependentOn = webFormFields["CollegeSetUpUSI"]
                    }
                    else {
                        dependentOn = webFormFields["CollegeSetUpUSI"] && webFormFields["AlreadyHaveUSI"] && JSON.parse(webFormFields["CollegeSetUpUSI"]) == 1 && JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2
                    }
                    formField.isMandatory = dependentOn
                    formField.error = false
                }


                return <SelectBox dependentOn={dependentOn} name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} data={DropDowns[fieldName]} />
                break
            case 3:
                // if (fieldName == "ResidencyDate") {
                //     dependentOn = parseInt(webFormFields["CitizenshipStatus"]) == 3 ? true : false
                //     formField.isMandatory = dependentOn
                // }                                                
                return (fieldName == "DateOfBirth" ?
                    <DateBox dependentOn={dependentOn} name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} maxDate={moment().add(-1 * webForm.minAgeEligibility, 'years').toDate()} value={webFormFields[fieldName]} onChange={this.handleChange} /> :
                    <DateBox dependentOn={dependentOn} name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} />
                )
                break
            case 4:
                return <TextBox name={fieldName} dependentOn={dependentOn} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 5:
                // console.log(fieldName)
                return <TextBox name={fieldName} dependentOn={dependentOn} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 6:

                if (fieldName == "Disability") {
                    dependentOn = webFormFields["HasDisability"] && JSON.parse(webFormFields["HasDisability"]) == 1
                    formField.isMandatory = dependentOn
                    formField.error = false
                }


                return <MultiSelectBox name={fieldName} dependentOn={dependentOn} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} data={DropDowns[fieldName]} />
                break
            case 7:
                // console.log(fieldName.indexOf("Suburb"))
                if (fieldName.indexOf("Suburb") > 0) {
                    if (fieldName == "IntrnationalSuburbID") {

                    }
                    else {
                        return (
                            <>
                                <AutoComplete name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleSuburb} selectedItem={this.state["selected" + fieldName] && this.state["selected" + fieldName]} />
                                <TextBox dependentOn={dependentOn} readOnly={true} name={fieldName + "State"} error={error} label={"State"} value={webFormFields[fieldName + "State"]} />
                                <TextBox dependentOn={dependentOn} readOnly={true} name={fieldName + "PostCode"} error={error} label={"Post Code"} value={webFormFields[fieldName + "PostCode"]} />
                                <TextBox dependentOn={dependentOn} readOnly={true} name={fieldName + "Country"} error={error} label={"Country"} value={webFormFields[fieldName + "Country"]} />
                            </>
                        )

                    }
                }
                else {

                    if (fieldName == "AgentId") {
                        dependentOn = webFormFields["ReferredByAgent"] || false
                    }
                    return <AutoComplete name={fieldName} error={error} endPoint="Agents/List" dependentOn={dependentOn} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleAgent} />
                }
                break
            case 8:
                var tempVal = false
                // console.log(fieldName)
                if (fieldName == "PriorVisaRefusal") {
                    if (webFormFields["VisaType"] && ((parseInt(webFormFields["VisaType"]) >= 1 && parseInt(webFormFields["VisaType"]) <= 7) || parseInt(webFormFields["VisaType"]) == 18 || parseInt(webFormFields["VisaType"]) == 25)) {

                    }
                    else {
                        dependentOn = false
                    }
                }
                else if (fieldName == "IELTSTestCurrent") {
                    // dependentOn = webFormFields["EnglishFirstLanguage"]
                }
                else if (fieldName == "USIPermission") {
                    if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
                        tempVal = true
                    }
                    else {
                        tempVal = webFormFields["AlreadyHaveUSI"] && JSON.parse(webFormFields["AlreadyHaveUSI"]) == 1
                    }
                    dependentOn = tempVal
                }
                else if (fieldName == "CollegeSetUpUSI") {
                    if (window.webformconfigs && window.webformconfigs.showOldUSISection) {
                        tempVal = true;
                        dependentOn = tempVal
                    }
                    else {
                        tempVal = webFormFields["AlreadyHaveUSI"] && JSON.parse(webFormFields["AlreadyHaveUSI"]) == 2
                        dependentOn = tempVal
                    }
                }

                return <CheckBox dependentOn={dependentOn} hintText={extraLabel} error={error} label={fieldLabel} name={fieldName} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 9:
                if (fieldName == "OnshoreOffShoreStatus") {
                    dependentOn = webFormFields["OverseasStudent"]
                }
                else if (fieldName == "Medical_Flag") {
                    dependentOn = webFormFields["HasDisability"] && JSON.parse(webFormFields["HasDisability"]) == 1
                }


                return <RadioBox dependentOn={dependentOn} error={error} value={webFormFields[fieldName]} name={fieldName} label={fieldLabel} onChange={this.handleChange} dataStr={formField.staticData} />
                break
            case 10:
                const data = DropDowns[fieldName]
                const staticData = [
                    { idField: "A", valueField: "A - Australian" },
                    { idField: "E", valueField: "E - Australian Equivalent" },
                    { idField: "I", valueField: "I - International" }
                ]
                if (fieldName == "PriorAchievments") {
                    dependentOn = webFormFields["HasPriorAchievments"]
                    formField.isMandatory = dependentOn
                    formField.error = false
                }

                return (
                    <div className={"col-span-8 sm:col-span-4" + (!dependentOn ? " hidden" : "")}>
                        <FormLabel
                            key={fieldLabel + 1}
                            text={fieldLabel}
                            color={error ? "red" : "black"}
                        />
                        <div className=" overflow-auto h-32">
                            <div className="grid grid-cols-3 gap-3">
                                {data && data.map(ach => (
                                    <>
                                        <div className="col-span-2">
                                            <CheckBox dependentOn={true} name="chkachievement" error={error} label={ach["valueField"]} value={ach.chkachievement} onChange={(event) => this.handleAchievementsChange(event, ach)} />
                                        </div>
                                        <div className="col-span-1">
                                            <SelectBox dependentOn={dependentOn} name="selachievement" label={" "} value={ach.selachievement} onChange={(event) => this.handleAchievementsChange(event, ach)} data={staticData} />
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div >

                )
                // return <GroupedControlBox dependentOn={dependentOn} label={fieldLabel} name={fieldName} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 11:
                return <TextAreaBox rows="3" dependentOn={dependentOn} name={fieldName} error={error} label={fieldLabel + (isMandatory ? "*" : "")} value={webFormFields[fieldName]} onChange={this.handleChange} />
                break
            case 12:
                // if (fieldName == "OnshoreOffShoreStatus") {
                //     dependentOn = webFormFields["OverseasStudent"]

                // }
                let currentFieldValue = webFormFields[formField.fieldName]
                let chGroupData = []
                const sData = formField.staticData
                if (sData != "") {
                    const dataArr = sData.split(",");
                    dataArr.forEach(dt => {
                        let tempArray = dt.split("|");
                        let dataObj = {}
                        dataObj.idField = tempArray[0]
                        dataObj.valueField = tempArray[1]
                        dataObj.checked = ("," + webFormFields[fieldName] + ",").indexOf("," + tempArray[0] + ",") >= 0 ? true : false
                        if (formField.maxSelectionCount > 0)
                            dataObj.disabled = !dataObj.checked && currentFieldValue && currentFieldValue.split(",").length >= formField.maxSelectionCount ? true : false
                        else
                            dataObj.disabled = false
                        chGroupData.push(dataObj)
                    });

                }


                return <CheckBoxGroup error={formField.error} dependentOn={dependentOn} name={fieldName} label={fieldLabel} onChange={(event) => this.handleCheckBoxGroup(event, chGroupData)} data={chGroupData} />
                break
            default:
                return "";
        }
    }

    updateCourse = (courses) => {
        // debugger
        const { webFormFields } = this.state
        webFormFields.courses = courses
        this.setState({ webFormFields: webFormFields })
    }
    updatePreference = (preferences) => {
        const { webFormFields } = this.state
        webFormFields.preferences = preferences
        this.setState({ webFormFields: webFormFields })
    }
    updateReference = (references) => {
        const { webFormFields } = this.state
        webFormFields.references = references
        this.setState({ webFormFields: webFormFields })
    }
    updateContact = (contacts) => {
        const { webFormFields } = this.state
        webFormFields.contacts = contacts
        this.setState({ webFormFields: webFormFields })
    }

    setFiles = (files, doctype) => {
        const { webFormFields } = this.state
        const currentDocumentObj = webFormFields.documents.filter(doc => doc.docTypeID == doctype)[0]
        currentDocumentObj.files = files
        // console.log(doctype)
        // console.log(files)
        this.setState({ webFormFields })
    }

    renderCustomSections(content, formSectionID, formSectionTitle, formFields, expanded) {
        const { applicationFor, programId, applicationObjects, programIntakes, intakes, webFormCampus, uploadDocuments, allowOneProgramOnly } = this.state.webForm
        const { webFormFields, DropDowns } = this.state
        const { courses, preferences, references, contacts } = webFormFields
        const { webForm } = this.state

        if (!webForm || !webForm.webFormDetail)
            return

        const { formSections } = webForm.webFormDetail
        const section = formSections.filter(fs => fs.formSectionID == formSectionID)[0]
        switch (formSectionID) {
            case 1:
                return <CourseSection label={formSectionTitle} allowMultiplePrograms={!allowOneProgramOnly} onChange={this.updateCourse} course={courses} courseType={applicationFor} program={programId} campuses={webFormCampus} campusObjects={applicationObjects} intakes={programIntakes} />
                break
            case 2:
                if (uploadDocuments.length > 0) {
                    return (
                        <FormSection expanded={expanded} headerClick={() => { expanded = !expanded }} label={formSectionTitle}>
                            {uploadDocuments.map(upd => <Uploader error={upd.error} onUpload={this.setFiles} docTypeId={upd.docTypeID} label={upd.docType} key={upd.docTypeID} />)}
                        </FormSection>
                    )
                }
                break
            case 3:
            case 4:
            case 5:
            case 40:
                let fieldName = "DeclarationChecked"
                if (formSectionID == 3) fieldName = "TermsChecked"
                if (formSectionID == 5) fieldName = "AgentTermsChecked"
                if (formSectionID == 40) fieldName = "PersonalConsent"

                return (
                    <FormSection expanded={expanded} headerClick={() => { expanded = !expanded }} label={formSectionTitle}>
                        <div className={"sm:col-span-6 overflow-auto h-80 " + (section.error ? " border border-red-400" : "")} >
                            {ReactHtmlParser(content)}
                        </div>
                        <div className="border-t border-gray-400 sm:col-span-6">
                            <div className="max-w-full mx-auto justify-center text-center">
                                {formSectionID == 40 ?
                                    <div class="flex flex-col justify-center items-center mx-auto mt-10">
                                        <RadioBox dependentOn={true} value={webFormFields[fieldName]} name={fieldName} align="justify-center" onChange={this.handleChange} dataStr="1|I Agree,2|I do not Agree" />
                                    </div>
                                    :
                                    <CheckBox label="I Agree" dependentOn={true} name={fieldName} value={webFormFields[fieldName]} align="justify-center" onChange={this.handleChange} />
                                }
                            </div>
                        </div>

                    </FormSection>
                )
                break
            case 22:
                return <PreferencesSection label={formSectionTitle} onChange={this.updatePreference} preference={preferences} campuses={webFormCampus} programs={applicationObjects} />
                break
            case 24:
                return <ReferenceSection label={formSectionTitle} onChange={this.updateReference} references={references} />
                break
            case 29:
                return <ContactSection dropDowns={DropDowns} label={formSectionTitle} onChange={this.updateContact} contactFields={formFields} contacts={contacts} />
                break
            case 34:
                return <SignaturePad error={section.error} label={formSectionTitle} reference={(ref) => { this.sigPad = ref }} clearFn={this.clearSignature} onEnd={this.trim} />

            default:
                return ""
        }
    }

    renderWebform() {
        const { webForm, webFormFields } = this.state

        if (!webForm || !webForm.webFormDetail)
            return

        const { formSections } = webForm.webFormDetail
        // console.log("in render webform")



        return (
            formSections.map(({ content, formSectionID, formSectionTitle, formFields, expanded }) => (
                formSectionID < 6 || formSectionID == 22 || formSectionID == 24 || formSectionID == 29 || formSectionID == 34 || formSectionID == 40 ? this.renderCustomSections(content, formSectionID, formSectionTitle, formFields, expanded, formFields) : (
                    <FormSection expanded={expanded} headerClick={() => { expanded = !expanded }} label={formSectionTitle} >


                        {
                            formFields.map((formField) => (
                                this.renderField(formField)
                            ))
                        }
                    </FormSection>

                )

            ))
        )
    }

    submitForm = () => {
        // return <Redirect to={"/webform/" + this.props.match.params.id + "/quiz/1"} />;
        // window.location.reload()
        const { webForm, webFormFields, DropDowns } = this.state
        const { formSections } = webForm.webFormDetail
        const { courses, preferences, references, contacts } = webFormFields
        // debugger
        let hasErrors = false;
        let hasSignatureSection = false;
        if (webForm.formType == 4) {
            preferences.map((preference) => {
                const keys = Object.keys(preference)
                const { error } = preference
                keys.map(function (key) {
                    if (key !== "error") {
                        if (preference[key] == null || preference[key] == undefined) {
                            error[key] = true
                            return
                        }

                        if (preference[key].constructor == String().constructor && preference[key].trim() != "" && preference[key].trim() != "0") {
                            error[key] = false
                            return
                        }
                        error[key] = true
                    }
                }, preference)
            })
        }
        else if (webForm.formType == 5) {
            references.map((reference) => {
                const keys = Object.keys(reference)
                const { error } = reference
                keys.map(function (key) {
                    if (key !== "error") {
                        if (reference[key] == null || reference[key] == undefined) {
                            error[key] = true
                            return
                        }

                        if (reference[key].constructor == String().constructor && reference[key].trim() != "" && reference[key].trim() != "0") {
                            error[key] = false
                            return
                        }
                        error[key] = true
                    }
                }, reference)
            })
            contacts.map((contact) => {
                const keys = Object.keys(contact)
                const { error } = contact
                keys.map(function (key) {
                    if (key !== "error") {
                        if (contact[key] == null || contact[key] == undefined) {
                            error[key] = true
                            return
                        }

                        if (contact[key].constructor == String().constructor && contact[key].trim() != "" && contact[key].trim() != "0") {
                            error[key] = false
                            return
                        }
                        error[key] = true
                    }
                }, contact)
            })
        }
        else if (webForm.formType <= 3) {
            courses.map((course) => {
                const keys = Object.keys(course)
                const { error } = course
                const { courseType } = { webForm }
                keys.map(function (key) {
                    if (key !== "error" && key != "HolidayWeeks") {
                        if (courseType == 9 && (course[key] == "UOS" || course[key] == "Subject")) {
                            error[key] = false
                            return
                        }
                        if (courseType == 22 && course[key] == "UOS") {
                            error[key] = false
                            return
                        }

                        if (courseType == 23 && course[key] == "Subject") {
                            error[key] = false
                            return
                        }

                        if (course[key] == null || course[key] == undefined) {
                            error[key] = true
                            return
                        }
                        if (course[key].constructor == Number().constructor && course[key] > 0) {
                            error[key] = false
                            return
                        }
                        if (course[key].constructor == String().constructor && course[key].trim() != "" && course[key].trim() != "0") {
                            error[key] = false
                            return
                        }

                        if (course[key].constructor == new Date().constructor) {
                            error[key] = false
                            return
                        }
                        error[key] = true
                    }
                }, course)
            })
        }
        try {
            if (!hasErrors) {
                if (webForm.uploadDocuments) {
                    webForm.uploadDocuments.map(dt => {
                        if (dt.mandatory) {
                            if (dt.files && dt.files.length > 0) {
                                dt.error = false
                            }
                            else {
                                dt.error = true
                                hasErrors = true
                            }
                        }
                    })
                }
                formSections.map(({ formSectionID, formFields }) => {
                    if (formSectionID != 29 && formSectionID != 34) {
                        formFields.map((formField) => {
                            formField.error = false
                            if (formField.isMandatory) {
                                if (formField.fieldName == "Email" && webFormFields[formField.fieldName] && webFormFields[formField.fieldName].trim() != "") {
                                    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                                    if (!pattern.test(webFormFields[formField.fieldName])) {
                                        formField.error = true
                                        return
                                    }
                                }
                                if (formField.fieldName == "Mobile" && webFormFields[formField.fieldName] && webFormFields[formField.fieldName].trim() != "") {
                                    var pattern = new RegExp(/^[0-9\b]+$/);
                                    // pattern = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
                                    pattern = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
                                    console.log(pattern.test(webFormFields[formField.fieldName]));
                                    if (!pattern.test(webFormFields[formField.fieldName])) {
                                        formField.error = true
                                        return
                                    }
                                    // else if (webFormFields[formField.fieldName].length != 10) {
                                    //     formField.error = true
                                    //     return
                                    // }

                                }
                                if (webFormFields[formField.fieldName] && (webFormFields[formField.fieldName].constructor == new Boolean().constructor || webFormFields[formField.fieldName].constructor == new Array().constructor)) {
                                    formField.error = false
                                    return
                                }
                                if (!webFormFields[formField.fieldName]) {
                                    formField.error = true
                                    hasErrors = true
                                }
                                else if (webFormFields[formField.fieldName].constructor != new Date().constructor && webFormFields[formField.fieldName].constructor != new Number().constructor && webFormFields[formField.fieldName].trim() == "") {
                                    formField.error = true
                                    hasErrors = true
                                }
                                else if (webFormFields[formField.fieldName].constructor == new Number().constructor && !(parseInt(webFormFields[formField.fieldName]) > 0)) {
                                    formField.error = true
                                    hasErrors = true
                                }
                                else if (formField.minSelectionCount > 0) {
                                    let cVal = webFormFields[formField.fieldName];
                                    if (cVal.split(",").length < formField.minSelectionCount) {
                                        formField.error = true
                                        hasErrors = true
                                    }
                                }
                                if (formField.fieldName == "PriorAchievments") {
                                    if (webFormFields["PriorAchievments"] && webFormFields["PriorAchievments"] != "") {
                                        let strPriorAchievements = webFormFields["PriorAchievments"].split(",");
                                        let errorFlag = false
                                        strPriorAchievements.forEach(function (item, index) {
                                            if (item.indexOf("|") < 0) {
                                                formField.error = true
                                                hasErrors = true
                                                return
                                            }
                                        })

                                    }
                                }
                            }
                            else if (formField.fieldName == "CollegeSetUpUSI" || formField.fieldName == "USIPermission") {
                                if (!webFormFields["CollegeSetUpUSI"] && !webFormFields["USIPermission"]) {
                                    formField.error = true
                                    hasErrors = true
                                }
                            }
                            // else if(formField.fieldName == "PriorAchievments"){
                            //     if(webFormFields["PriorAchievments"] && webFormFields["PriorAchievments"] != ""){
                            //         let strPriorAchievements = webFormFields["PriorAchievments"].split(",");
                            //         let errorFlag = false
                            //         strPriorAchievements.forEach(function(item, index){
                            //             if(item.indexOf("|")<0)
                            //             errorFlag = true
                            //         })
                            //         formField.error = errorFlag
                            //     }
                            // }
                        })
                    }
                })

                const customSections = [...formSections.filter(fs => fs.formSectionID == 3 || fs.formSectionID == 4 || fs.formSectionID == 34 || fs.formSectionID == 40)]
                customSections.map(section => {
                    section.error = false
                    if (section.formSectionTitle == "Terms and Conditions") {
                        if (!webFormFields["TermsChecked"]) {
                            hasErrors = true
                            section.error = true
                        }
                    }
                    if (section.formSectionTitle == "Student Declaration") {
                        if (!webFormFields["DeclarationChecked"]) {
                            hasErrors = true
                            section.error = true
                        }
                    }
                    if (section.formSectionTitle == "Personal Consent") {
                        if (!webFormFields["PersonalConsent"]) {
                            hasErrors = true
                            section.error = true
                        }
                    }
                    if (section.formSectionTitle == "Signature") {
                        hasSignatureSection = true;
                        if (!this.sigPad) {
                            hasErrors = true
                            section.error = true
                        }
                        else {
                            if (this.sigPad.isEmpty()) {
                                hasErrors = true
                                section.error = true
                            }
                        }
                    }
                })
            }

        }
        catch (e) {
            alert("An error occured while validating form. Please try again after some time.")
        }
        // vaidation failed
        if (hasErrors) {
            alert("Your application submitted has errors. Please review your answers above and fix any errors.");
            this.setState({ webForm: webForm })
        } else {
            this.setState({ modalState: true, modalMessage: "Saving Form please wait" })
            // save webform data
            // send to quiz if any
            // this.props.history.push("/webform/" + this.props.match.params.id + "/application/1/quiz/1")
            // window.location.reload()

            // send file code

            // form.append(docs, JSON.stringify(webFormFields));

            // const json = JSON.stringify(webFormFields);
            // const blob = new Blob([json], {
            //     type: 'application/json'
            // });


            // for (var key in webFormFields) {
            //     if (webFormFields.hasOwnProperty(key)) {
            //         var element = state.fields[key];
            //         form.append(key, element);
            //     }
            // }            

            webFormFields.contacts.map(c => {
                c.Position = parseInt(c.Position)
            })
            webFormFields.courses.map(c => {
                c.Campus = parseInt(c.Campus)
                c.Intake = parseInt(c.Intake)
                c.Program = parseInt(c.Program)
                c.AgentId = parseInt(localStorage.getItem("agentId"))
            })
            webFormFields.preferences.map(c => {
                c.Campus = parseInt(c.Campus)
                c.Program = parseInt(c.Program)
            })
            if (webFormFields.LastSchoolYear) webFormFields.LastSchoolYear = parseInt(webFormFields.LastSchoolYear)
            if (webFormFields.NoOfStudentReferred) webFormFields.NoOfStudentReferred = parseInt(webFormFields.NoOfStudentReferred)
            if (webFormFields.PersonalConsent) webFormFields.PersonalConsent = parseInt(webFormFields.PersonalConsent)

            const webFormPost = { ...webFormFields }
            // if (webFormFields.HasPriorAchievments && DropDowns["PriorAchievments"]) {
            //     const achievements = DropDowns["PriorAchievments"]
            //     webFormPost.PriorAchievments = ""
            //     achievements.forEach(ach => {
            //         if (ach.chkachievement) {
            //             if (webFormPost.PriorAchievments != "") webFormPost.PriorAchievments += ","
            //             webFormPost.PriorAchievments += ach.avetCodeID
            //             if (ach.selachievement) webFormPost.PriorAchievments += "|" + ach.selachievement
            //         }
            //     })
            // }
            if (webFormFields.Disability && webFormFields.Disability.length > 0) {
                webFormPost.Disability = webFormFields.Disability.map(x => x.avetCodeID).toString()
                webFormFields.Disability = webFormPost.Disability
            }

            Axios.post(constants.BASEURL + 'WebForms/SaveWebForm', webFormFields)
                .then(async (result) => {

                    let message = result.data
                    if (message.status == 200) {
                        // this.setState({ modalMessage: "Uploading Documents Please wait..." })
                        // if (webFormFields.documents.length > 0) this.setState({ modalMessage: "Uploading Documents Please wait..." })
                        // else {
                        //     setTimeout(() => {
                        //         this.setState({ modalMessage: "Application Submitted Successfully." })
                        //     }, 3000);
                        // }
                    }
                    else {
                        throw result.data.message
                    }
                    // this.setState({
                    //     ...this.state,
                    //     formServiceResponse: message
                    // });
                    // this.setState({ modalMessage: "Uploading Documents Please wait..." })

                    // store document sync to be called here

                    if (hasSignatureSection) {
                        this.storeSignaturesSync(webFormFields, message.applicantId)
                    }
                    else {
                        this.storeDocumentsSync(webFormFields, message.applicantId, 0);
                    }

                    // Axios.post(constants.BASEURL + 'WebForms/SaveFormDocuments', form)
                    //     .then((result) => {
                    //         console.log("Documents saved successfully")
                    //     })
                    //     .catch((ex) => console.error(ex))
                })
                .catch((ex) => {
                    // debugger;
                    alert("An Error occured while saving webform");
                    this.setState({ modalState: false })
                });
        }



    }

    storeSignaturesSync = (webFormFields, applicantId) => {
        let canvas = this.sigPad.getCanvas()
        canvas.toBlob((blob) => {
            const fd = new window.FormData()
            // fd.append('signature', blob, 'signature.png')
            // POST to server with your preferred requests library
            let form = new FormData();
            form.append('file', blob, webFormFields.FirstName.trim() + webFormFields.LastName.trim() + "_signature.png");
            form.append("applicantId", applicantId);
            form.append("docTypeId", 0);
            form.append("applicantType", webFormFields.applicationType);
            form.append("isSignature", 1);
            try {
                Axios.post(constants.BASEURL + 'WebForms/SaveFormDocuments', form)
                    .then(result => {
                        // debugger
                        if (result.data.success = false) {
                            this.setState({ modalMessage: " Unabe to upload signature. " })
                        }
                        else {
                            this.setState({ modalMessage: " Signature uploaded. " })
                            this.storeDocumentsSync(webFormFields, applicantId, 0);
                            // this.storeDocumentsSync(webFormFields, applicantId, iteration + 1, hasSignatureSection)
                            // if (indx == documents.length - 1) {
                            //     setTimeout(() => {
                            //         this.setState({ modalMessage: "Application Submitted Successfully." })
                            //     }, 3000);
                            // }
                        }
                    })
            }
            catch (e) {
                this.setState({ modalMessage: " An Error occured while uploading signature. Please try later." })
            }
        })
    }

    storeDocumentsSync = (webFormFields, applicantId, iteration) => {
        const { documents } = webFormFields;
        if (iteration < documents.length) {
            let form = new FormData();
            if (documents[iteration].files) {

                for (var index = 0; index < documents[iteration].files.length; index++) {
                    var element = documents[iteration].files[index];
                    form.append('file', element);
                }
                form.append("applicantId", applicantId);
                form.append("docTypeId", documents[iteration].docTypeID);
                form.append("applicantType", webFormFields.applicationType);
                try {
                    Axios.post(constants.BASEURL + 'WebForms/SaveFormDocuments', form)
                        .then(result => {
                            if (result.data.success = false) {
                                this.setState({ modalMessage: documents[iteration].docType + " failed. " })
                            }
                            else {
                                this.setState({ modalMessage: documents[iteration].docType + " uploaded. " })
                                this.storeDocumentsSync(webFormFields, applicantId, iteration + 1)
                                // if (indx == documents.length - 1) {
                                //     setTimeout(() => {
                                //         this.setState({ modalMessage: "Application Submitted Successfully." })
                                //     }, 3000);
                                // }
                            }
                        })
                }
                catch (e) {
                    this.setState({ modalMessage: " An Error occured while uploading documents. Please try later." })
                }
            }
            else {
                this.storeDocumentsSync(webFormFields, applicantId, iteration + 1)
            }
        }
        else {
            setTimeout(() => {
                this.setState({ modalMessage: "Form Submitted Successfully." })
            }, 3000);

        }
        //old code to store documents
        // webFormFields.documents.forEach(async (doc, indx) => {
        //     let form = new FormData();
        //     for (var index = 0; index < doc.files.length; index++) {
        //         var element = doc.files[index];
        //         form.append('file', element);
        //     }
        //     form.append("applicantId", message.applicantId);
        //     form.append("docTypeId", doc.docTypeID);
        //     form.append("applicantType", webFormFields.applicationType);
        //     try {
        //         await Axios.post(constants.BASEURL + 'WebForms/SaveFormDocuments', form)
        //             .then(result => {
        //                 if (result.data.sucess = false) {
        //                     this.setState({ modalMessage: doc.docType + " failed. " })
        //                 }
        //                 else {
        //                     this.setState({ modalMessage: doc.docType + " uploaded. " })
        //                     if (indx == webFormFields.documents.length - 1) {
        //                         setTimeout(() => {
        //                             this.setState({ modalMessage: "Application Submitted Successfully." })
        //                         }, 3000);
        //                     }
        //                 }
        //             })

        //     }
        //     catch (e) { alert("Error while uploading " + doc.docType) }
        // })
    }

    afterQuizCompleted = (quizId) => {
        const completedQuiz = [...this.state.completedQuizes, quizId]
        const allQuiz = this.state.webForm.quizIds.split(',')
        const pendingQuiz = allQuiz.filter(x => !completedQuiz.includes(x));
        this.setState({ completedQuiz: completedQuiz })

        if (pendingQuiz.length > 0) {
            this.props.history.push("/webform/" + this.props.match.params.id + "/application/1/quiz/" + pendingQuiz[0])
            window.location.reload()
        }

    }

    render() {
        const { webForm, modalState, modalMessage, buttonList, isPreview } = this.state
        // commenting quiz work for now
        if (this.props.match.params.quizId > 0) {
            return <AnswerQuiz afterUpdate={this.afterQuizCompleted} quizId={this.props.match.params.quizId} objectType={1} objectId={1} />
        }
        else {
            return (
                <>

                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="mb-4">
                            <form name='webform' >
                                {this.renderWebform()}
                                <div className="text-right sm:col-span-2">
                                    <span className="inline-flex rounded-md shadow-sm">
                                        <button type="button" onClick={this.submitForm} className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                            {webForm && (webForm.quizIds) != "" ? "Next >" : "Submit"}
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Modal modalState={modalState} buttonsList={modalMessage == "Form Submitted Successfully." ? buttonList : []} headingText='Form Status'>
                        <div className="text-center">{modalMessage}</div>
                    </Modal>
                </>
            )
        }
    }
}

export default WebForm;