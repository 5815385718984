import React, { Component } from "react";

class Image extends Component {
  state = {};
  render() {
    return (
      <img src={"../../assets/images/" + this.props.src} />
    );
  }
}

export default Image;
