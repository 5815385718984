import React, { Component } from "react";
import Loader from "react-loader";
import TableComponent from "./common/TableComponent";
import Axios from "axios";
import moment from "moment";
import DateBox from "./controls/DateBox";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, SearchFilter } from "./common/commonFns";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import SearchBox from "./controls/SearchBox";
import ToolTip from "./common/ToolTip";
import Sms from "./common/sendMessage";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class OverDues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
      isLoaded: true,
      agentId: localStorage.getItem("agentId"),
      tableRows: [],
      tableEmpty: "hidden",
      invoiceDetailsData: [],
      firstRowIndex: 0,
      lastRowIndex: constants.NUMBEROFROWS - 1,
      filterOn: false,
      Mblhidden: "hidden",
      dataLength: 0,
      studentMobile: "",
      searchStudentValue: "",
      searchInvoiceValue: "",
      isOpen: false,
      expanded: "",
      background: "hidden",
      fromDate: "",
      toDate: "",
      overDueInvoiceData: [],
    };
    this.setPageIndexFn = this.setPageIndexFn.bind(this);
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentDidMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = localStorage.getItem("t2StartDate");
    let endDate = localStorage.getItem("t2EndDate");
    this.setState(
      {
        fromDate: startDate,
        toDate: endDate,
      },
      function () {
        this.overDueInvoices(startDate, endDate);
      }
    );
  }

  overDueInvoices(startDate, endDate) {
    agentsRequests.overDueInvoices(startDate, endDate).then((result) => {
      this.overDueInvoicesStudentDetails(startDate, endDate, result);
    });
  }

  overDueInvoicesStudentDetails(startDate, endDate, invoiceData) {
    Axios.get(
      constants.BASEURL +
        "Agents/OverDueInvoiceDetails/" +
        this.state.agentId +
        "/" +
        startDate +
        "/" +
        endDate
    )
      .then((response) => {
        let invoiceDetailsData = response.data;
        let data = invoiceData;

        let FinalOverdueInvoiceData = [];
        let struct = {
          studentInvoiceID: "",
          studentInvoiceNo: "",
          studentName: "",
          invoiceAmt: "",
          receiptAmt: "",
          balanceAmt: "",
          studentEmail: "",
          studentDOB: "",
          studentMobile: "",
          studentNo: "",
          enrolmentNo: "",
          dueDate: "",
        };

        data.map((object) => {
          //console.log(JSON.stringify(invoiceDetailsData));
          const a = invoiceDetailsData.find(
            (element) => element.studentInvoiceID === object.studentInvoiceID
          );

          if (a) {
            struct.studentInvoiceID = object.studentInvoiceID;
            struct.studentInvoiceNo = a.invoiceNo;
            struct.studentName = a.studentName;
            struct.invoiceAmt = currencyFormat(object.invoiceAmt);
            struct.receiptAmt = currencyFormat(object.receiptAmt);
            struct.balanceAmt = currencyFormat(object.balanceAmt);
            struct.studentEmail = a.studentEmail;
            struct.invoiceDate = a.invoiceDate;
            struct.dueDate = a.dueDate;
            struct.studentMobile = a.studentMobile;
            struct.studentNo = a.studentNo;
            struct.enrolmentNo = a.enrolmentNo;
            FinalOverdueInvoiceData.push({ ...struct });
          }
        });

        this.setState(
          {
            invoiceDetailsData: FinalOverdueInvoiceData,
            dataLength: FinalOverdueInvoiceData.length,
          },
          function () {
            this.loadData();
          }
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  loadData() {
    let tableRows = [];
    let formatedRowData = {};
    let data = this.state.invoiceDetailsData;
    let firstRowIndex = this.state.firstRowIndex;
    let lastRowIndex = this.state.lastRowIndex;

    data = this.searchItems(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 = " Student No:" + object.studentNo + ", Enrolment No:" + object.enrolmentNo;
          formatedRowData.Label2 = object.studentInvoiceNo;
          let invoiceDate = moment(object.invoiceDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          formatedRowData.DescriptionClm2 = "Invoice Date: " + invoiceDate;
          formatedRowData["StudentMobile"] = object.studentMobile;
          formatedRowData["Due Date"] = moment(
            object.dueDate,
            "DD/MM/YYYY"
          ).format("DD-MM-YYYY");
          formatedRowData["Student Mobile"] = object.studentMobile;
          formatedRowData["Invoiced"] = "$" + object.invoiceAmt;

          formatedRowData["Paid"] = "$" + object.receiptAmt;
          formatedRowData.Value = "$" + object.balanceAmt;
          // formatedRowData[" "] = (
          //   <div className="relative flex justify-end">
          //     <button
          //       Key={"message" + index}
          //       onClick={() => {
          //         this.eventHandeler(index, "SMS");
          //       }}
          //       className=" mx-2 my-1 w-5 h-5 inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          //     >
          //       <div className="tooltip">
          //         <svg
          //           className="w-5 h-5"
          //           fill="currentColor"
          //           viewBox="0 0 20 20"
          //           xmlns="http://www.w3.org/2000/svg"
          //         >
          //           <path
          //             fillRule="evenodd"
          //             d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
          //             clipRule="evenodd"
          //           />
          //         </svg>
          //         <ToolTip text={"Click to send a SMS"} />
          //       </div>
          //     </button>
          //   </div>
          // );
          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      this.setState({ tableEmpty: "" });
    }

    this.setState({ tableRows: tableRows });

    this.setState({
      hidden: "hidden",
      isLoaded: true,
    });
  }

  eventHandeler(index, event) {
    let tableRows = this.state.tableRows;

    if (event === "SMS") {
      const stdMobile = tableRows[index]["Student Mobile"];
      if (stdMobile) {
        this.setState(
          {
            studentMobile: stdMobile,
            Mblhidden: "",
            background: "",
          },
          function () {
            this.renderMobile();
          }
        );
      } else {
        alert("Mobile Number not Available");
      }
    } else if (event === "close" || event === "success") {
      this.setState({
        studentMobile: "",
        Mblhidden: "hidden",
        background: "hidden",
      });
    }
  }

  renderMobile() {
    return (
      <Sms
        number={this.state.studentMobile}
        callBack={(event) => this.eventHandeler(0, event)}
      />
    );
  }

  searchItems(data) {
    let originalData = data;
    let filterOn = false;

    if (this.state.searchStudentValue != "") {
      data = SearchFilter(data, this.state.searchStudentValue, "studentName");
      filterOn = true;
    }

    if (this.state.searchInvoiceValue != "") {
      data = SearchFilter(
        data,
        this.state.searchInvoiceValue,
        "studentInvoiceNo"
      );
      filterOn = true;
    }

    if (data.length === originalData.length) {
      filterOn = false;
    }

    this.setState({ filterOn: filterOn, dataLength: data.length });
    return data;
  }

  renderTable() {
    let tableHeadings = [
      "Student Details",
      "Invoice Details",
      "Due Date",
      "Student Mobile",
      "Invoiced",
      "Paid",
      "Balance",
      " ",
    ];
    let tableRows = this.state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={this.state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={this.state.dataLength}
          setPageIndexFn={this.setPageIndexFn}
          firstRowIndex={this.state.firstRowIndex}
          lastRowIndex={this.state.lastRowIndex}
          clmTwoWidth={"w-40"}
        />
      );
    } else {
      return (
        <div
          className={
            this.state.tableEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  setPageIndexFn(firstRowIndex, lastRowIndex) {
    this.setState(
      { firstRowIndex: firstRowIndex, lastRowIndex: lastRowIndex },
      function () {
        this.loadData();
      }
    );
  }

  searchHandeler(id) {
    if (id === "student") {
      var searchValue = document.getElementById("student").value;
      this.setState(
        {
          searchStudentValue: searchValue,
          searchInvoiceValue: "",
        },
        function () {
          this.loadData();
        }
      );
    } else if (id === "invoice") {
      var searchValue = document.getElementById("invoice").value;
      this.setState(
        {
          searchInvoiceValue: searchValue,
          searchStudentValue: "",
        },
        function () {
          this.loadData();
        }
      );
    }
  }

  handleDateBox(e, name) {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel2", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t2StartDate", startDate);
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.overDueInvoices(startDate, endDate);
        }
      );
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t2EndDate", endDate);
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.overDueInvoices(startDate, endDate);
        }
      );
    }
  }

  render() {
    return (
      <div className="relative mt-6 align-middle min-h-full ">
        <span
          className={
            this.state.hidden +
            " z-20 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacityBackground"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>
        <div className={this.state.Mblhidden + " z-40  Mobile-View"}>
          {this.renderMobile()}
        </div>
        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"student"}
              label={"Search For Student Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.searchStudentValue}
              searchHandler={() => {
                this.searchHandeler("student");
              }}
            />
          </div>
          <div className="xl:col-span-2">
            <SearchBox
              id={"invoice"}
              label={"Search For Invoice Number"}
              svgType={"document"}
              placeholder={"Number"}
              value={this.state.searchInvoiceValue}
              searchHandler={() => {
                this.searchHandeler("invoice");
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              dependentOn={true}
              label="Due Date From"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              dependentOn={true}              
              id={"Pd1"}
              label="Due Date Till"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
        </div>

        <div>{this.renderTable()}</div>
        <span
          onClick={() => this.eventHandeler(0, "close")}
          className={
            this.state.background +
            " z-30 fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
          }
        />
      </div>
    );
  }
}

export default OverDues;
