import React, { Component } from "react";
import Pagination from "./Pagination";
import "../../assets/css/additional.css";

//******* Note
//these 11 key names for tableRows array elements should be same always i.e.
//clmTwoWidth={"eg. w-20, w-1/2 etc."}
// ImgSrc:"",
// Label1: "",
// PillTxt:"",
// PillClr:"red(example)",
// Status: "0 for cancelled 1 for active and empty for nothing",
// DescriptionClm1: "",
// Label2: "",
// DescriptionClm2: "",
// Value: "" Its second last column of the table, can be used with sorting too as done in pendingProposal.jsx
//" ": <div><buton></button></div> last column heading is mandtory can be used for any button if required as
//done in pendingProposal.jsx or the rows can be left empty

class TableComponent extends Component {
  state = {};

  componentWillMount() {}

  tableHeadingFn(ths) {
    return ths.map((th, index) => {
      if (index === 0) {
        return (
          <th className="thSticky h-5  pl-4 pr-4 py-3 border-b border-gray-200 bg-gray-200 text-left text-sm leading-4 font-semibold text-gray-600  tracking-wider">
            {th}
          </th>
        );
      } else if (index === 1) {
        return (
          <th className="thSticky h-5  pl-1 pr-2 py-3 border-b border-gray-200 bg-gray-200 text-left text-sm leading-4 font-semibold text-gray-600  tracking-wider">
            {th}
          </th>
        );
      } else if (index === this.props.tableHeadings.length - 2) {
        return (
          <th className="thSticky h-5  px-2 whitespace-no-wrap py-3 border-b bg-gray-200 border-gray-200  text-center text-sm leading-4 font-semibold text-gray-600  tracking-wider">
            {th}
          </th>
        );
      } else {
        return (
          <th className="thSticky h-5  px-2 py-3 border-b border-gray-200 bg-gray-200 text-center text-sm leading-4 font-semibold text-gray-600  tracking-wider">
            {th}
          </th>
        );
      }
    });
  }

  otherRowElements(trElements) {
    let headings = [...this.props.tableHeadings];
    let e = [];

    let color = "gray-900";

    headings.map((th, index) => {
      if (index > 1) {
        if (index === headings.length - 2) {
          let numericStr = trElements.Value.replace(/[^\d.-]/g, "");
          let dataItem = parseFloat(numericStr);

          if (dataItem < 0) {
            color = "red-500";
          } else {
            color = "gray-900";
          }
          e.push(
            <td
              className={
                "px-2 py-3 border-b border-gray-200 text-center text-xs leading-4 whitespace-no-wrap text-" +
                color
              }
            >
              {trElements.Value.replace("$-", "-$")}
            </td>
          );
        } else {
          e.push(
            <td
              className={
                "px-3 py-3 border-b border-gray-200 text-center text-xs leading-4 whitespace-no-wrap text-" +
                color
              }
            >
              {trElements[th]}
            </td>
          );
        }
      }
    });

    return e;
  }

  otherSmallViewElements(trElements) {
    let headings = [...this.props.tableHeadings];
    let e = [];
    let color = "gray-900";

    headings.map((th, index) => {
      if (index > 1 && index < headings.length - 1) {
        if (index === headings.length - 2) {
          let numericStr = trElements.Value.replace(/[^\d.-]/g, "");
          let dataItem = parseFloat(numericStr);

          if (dataItem < 0) {
            color = "red-500";
          } else {
            color = "gray-900";
          }
          e.push(
            <tr>
              <th className="w-35vh px-2 py-1 border-r border-gray-200 text-left text-xs leading-4 font-medium text-gray-600  tracking-wider">
                {th}
              </th>
              <td
                className={
                  "w-65vh px-2 py-2 text-left text-xs leading-4 whitespace-no-wrap text-" +
                  color
                }
              >
                <div className="mr-2 grid grid-cols-2">
                  <div>{trElements.Value.replace("$-", "-$")}</div>
                  <div className="">{trElements[" "]}</div>
                </div>
              </td>
            </tr>
          );
        } else {
          e.push(
            <tr>
              <th className="w-35vh px-2 py-2 border-r border-gray-200 text-left text-xs leading-4 font-medium text-gray-600  tracking-wider">
                {th}
              </th>
              <td
                className={
                  "w-65vh px-2 py-1  text-left text-xs leading-4 whitespace-no-wrap text-" +
                  color
                }
              >
                {trElements[th]}
              </td>
            </tr>
          );
        }
      }
    });

    return e;
  }

  renderStatus = (txt, clr) => {
    if (txt) {
      let color = clr;
      return (
        <span
          className={
            "px-2 inline-flex text-xs font-semibold rounded-full bg-" +
            color +
            "-200 text-" +
            color +
            "-800"
          }
        >
          {txt}
        </span>
      );
    }
  };

  tableRowClr(index) {
    if (index === 0) {
      return "bg-white";
    } else if (index % 2 === 0) {
      return "bg-white";
    } else if (index % 2 != 0) {
      return "bg-gray-50";
    }
  }
  previousPageFn() {
    const { setPageIndexFn } = this.props;

    let oldfirstRowIndex = parseInt(this.props.firstRowIndex);
    let lastRowIndex = oldfirstRowIndex - 1;
    if (lastRowIndex > 0) {
      let newFirstRowIndex =
        oldfirstRowIndex - this.props.numberOfElementsPerPage;

      setPageIndexFn(newFirstRowIndex, lastRowIndex);
    }
  }

  nextPageFn() {
    const { setPageIndexFn } = this.props;

    let oldLastRowIndex = parseInt(this.props.dataLength) - 1;
    let firstRowIndex = parseInt(this.props.lastRowIndex);
    let lastRowIndex = firstRowIndex + this.props.numberOfElementsPerPage;
    let newFirstRowIndex = firstRowIndex + 1;
    if (lastRowIndex > oldLastRowIndex) {
      lastRowIndex = oldLastRowIndex;
    }

    if (newFirstRowIndex <= oldLastRowIndex) {
      setPageIndexFn(newFirstRowIndex, lastRowIndex);
    }
  }

  firstPageFn() {
    const { setPageIndexFn } = this.props;
    let lastRowIndex = this.props.numberOfElementsPerPage - 1;
    setPageIndexFn(0, lastRowIndex);
  }

  lastPageFn() {
    const { setPageIndexFn } = this.props;
    let dataLength = parseInt(this.props.dataLength);
    let multiplier = parseInt(dataLength / this.props.numberOfElementsPerPage);
    let firstRowIndex = multiplier * this.props.numberOfElementsPerPage;
    let lastRowIndex = dataLength - 1;
    setPageIndexFn(firstRowIndex, lastRowIndex);
  }

  loadTable() {
    let tableRows = [...this.props.tableRows];
    let tableHeadings = [...this.props.tableHeadings];
    // console.log("t rows: " + tableRows.length);
    return (
      <div className="w-full">
        <div
          style={{ height: "60vh" }}
          className="relative align-middle  overflow-auto inline-block w-full h-full shadow  sm:rounded-lg border-b border-gray-200"
        >
          <div className="hidden xl:block ">
            <table className="w-full h-full ">
              <thead className="w-full">
                <tr>{this.tableHeadingFn(tableHeadings)}</tr>
              </thead>
              <tbody className="bg-white ">
                {tableRows.map((trElements, index) => (
                  <tr className={this.tableRowClr(index)}>
                    <td className="pl-3 pr-5 py-4 border-b border-gray-200 w-1/4">
                      <div className="flex items-center ">
                        <div className="grid grid-col-1 ">
                          <div className="flex justify-center">
                            <div className="h-8 w-8">
                              <img
                                className="h-8 w-8 rounded-full"
                                src={trElements.ImgSrc}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="mt-1">
                            {this.renderStatus(
                              trElements.PillTxt,
                              trElements.PillClr
                            )}
                          </div>
                        </div>
                        <div className="ml-2">
                          <div className="text-xs leading-5 text-gray-900">
                            {trElements.Label1}
                          </div>
                          <div className="text-xs leading-5 text-gray-500">
                            {trElements.DescriptionClm1}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={
                        this.props.clmTwoWidth +
                        " pl-1 pr-3 border-b border-gray-200"
                      }
                    >
                      <div className="text-xs leading-5 text-gray-900">
                        {trElements.Label2}
                      </div>
                      <div className="text-xs leading-5 whitespace-no-wrap text-gray-500">
                        {trElements.DescriptionClm2}
                      </div>
                    </td>

                    {this.otherRowElements(trElements)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <table className="xl:hidden w-full">
            {tableRows.map((trElements, index) => (
              <div className={"mb-2 bg-gray-100 border border-gray-200"}>
                <tr>
                  <th
                    className={
                      "w-35vh px-2 py-2 bg-gray-200 text-left text-xs font-medium text-gray-600"
                    }
                  >
                    <div className="items-center">
                      <div className="h-8 w-8 mx-2 ">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={trElements.ImgSrc}
                          alt=""
                        />
                      </div>
                      <div className="mt-1">
                        {this.renderStatus(
                          trElements.PillTxt,
                          trElements.PillClr
                        )}
                      </div>
                    </div>
                  </th>
                  <td className={"w-65vh bg-gray-200 text-right"}></td>
                </tr>
                <tr>
                  <th className="w-35vh px-2 py-2 border-r border-gray-200 text-left text-xs leading-4 font-medium text-gray-600  tracking-wider">
                    <div>{tableHeadings[0]}</div>
                  </th>
                  <td className="w-65vh px-2 py-2  ">
                    <div className="flex items-center ">
                      <div className="">
                        <div className="text-xs leading-5 font-medium text-gray-900">
                          {trElements.Label1}
                        </div>
                        <div className="text-xs leading-5 text-gray-500">
                          {trElements.DescriptionClm1}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th className="w-35vh px-2 py-2  text-left text-xs border-r border-gray-200 leading-4 font-medium text-gray-600  tracking-wider">
                    {tableHeadings[1]}
                  </th>
                  <td className="w-65vh px-2 py-2 ">
                    <div className="text-xs  leading-5 text-gray-900">
                      {trElements.Label2}
                    </div>
                    <div className="text-xs leading-5  text-gray-500">
                      {trElements.DescriptionClm2}
                    </div>
                  </td>
                </tr>
                {this.otherSmallViewElements(trElements)}
              </div>
            ))}
          </table>
        </div>
        <Pagination
          numberOfElementsPerPage={this.props.numberOfElementsPerPage}
          lengthOfData={this.props.dataLength}
          lengthOfTableRows={this.props.tableRows.length}
          filterOn={this.props.filterOn}
          previousPageFn={() => this.previousPageFn()}
          firstPageFn={() => this.firstPageFn()}
          lastPageFn={() => this.lastPageFn()}
          nextPageFn={() => this.nextPageFn()}
          firstRowIndex={this.props.firstRowIndex}
          lastRowIndex={this.props.lastRowIndex}
        />
      </div>
    );
  }

  render() {
    return this.loadTable();
  }
}

export default TableComponent;
