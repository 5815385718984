import React, { Component } from "react";
import Icon from "./Icon";

class ListItemDetail extends Component {
    state = {};
    render() {
        console.log(this.props);
        const { showControl, itemData } = this.props;

        if (showControl && !itemData) {
            return (
                < div className={"bg-white shadow overflow-hidden sm:rounded-md text-center " + (showControl ? "" : "hidden")}>
                    <img className='m-auto' src={require("../../assets/images/loader.gif")} />
                </div>
            )
        }
        // console.log(showControl + " = " + (itemData && itemData.constructor))
        return (
            < div className={"bg-white shadow overflow-hidden sm:rounded-md " + (showControl ? "" : "hidden")}>
                <div className=" shadow overflow-hidden  sm:rounded-lg" >
                    {itemData && itemData.map((item) => (
                        <div className={"px-4 py-5 sm:px-6 " + (parseInt(item.key) % 2 == 0 ? "bg-gray-100" : "")}>
                            <dl className="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
                                {item.headerData && item.headerData.map((headItem) => (
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm leading-5 font-medium text-gray-500">
                                            {headItem.label}
                                        </dt>
                                        <dd className="mt-1 text-sm leading-5 text-gray-900">
                                            {headItem.text}
                                        </dd>
                                    </div>
                                ))}

                                <div className="sm:col-span-2">
                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                        {item.listLabel}
                                    </dt>
                                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                                        <ul className="border border-gray-300 rounded-md">
                                            {item.listData && item.listData.map((listItem) => (
                                                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 border border-gray-300">
                                                    <div className="w-0 flex-1 flex items-center">
                                                        <svg className="flex-shrink-0 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clipRule="evenodd" />
                                                        </svg>
                                                        <span className="ml-2 flex-1 w-0 truncate">
                                                            {listItem.itemName}
                                                        </span>
                                                    </div>
                                                    <div className="ml-4 flex-shrink-0">
                                                        <a href={listItem.linkTarget} target="_blank" className="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
                                                            Download
                                                        </a>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    ))}
                </div>
            </ div>

        );
    }
}

export default ListItemDetail;
