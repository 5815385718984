// export const BASEURL = "https://api-test.rtosoftware.com.au/api/";
//export const BASEURL = "https://lmsapi.rtosoftware.com.au/api/";
// export const BASEURL = "https://localhost:5003/api/";
//export const BASEURL = "https://testapi.rtosoftware.com.au/api/";
//export const BASEURL = "http://192.168.18.22/api/";
//export const COLLEGEID = 20260;
export const BASEURL = window.configs.collegeName == "localhost" ? "https://localhost:5003/api/" : "https://api-" + window.configs.collegeName + ".rtosoftware.com.au/api/";
export const COLLEGEID = 301;

// Program Types
export const CURRENTPROGRAM = 1;
export const PASTPROGRAM = 2;

// Table Constants
export const NUMBEROFROWS = 50;
