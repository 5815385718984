export class DateFormatter {
  //following code converting  date to DD/MM/YYYY format
  formatter(date) {
    // console.log(date);
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }
    let formattedDate = objectDate + "/" + objectMonth + "/" + objectYear;
    return formattedDate;
  }
  //following code converting  date to YYYY-DD-MM i.e. ISO format format
  formatterTwo(date) {
    // console.log(date);
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }
    let formattedDate = objectYear + "-" + objectMonth + "-" + objectDate;
    return formattedDate;
  }
  formatterThree(date) {
    // console.log(date);
    let oDate = new Date(date);
    let objectYear = oDate.getFullYear();
    let objectMonth = oDate.getMonth() + 1;
    let objectDate = oDate.getDate();

    if (objectDate < 10) {
      objectDate = "0" + objectDate;
    }
    if (objectMonth < 10) {
      objectMonth = "0" + objectMonth;
    }
    let formattedDate =
      objectYear + "-" + objectMonth + "-" + objectDate + "T00:00:00";
    return formattedDate;
  }
}
export default DateFormatter;
