// In this component we have imported React, axios for http requests,
// constants, WideList component and Redirect
import React, { Component } from "react";
import axios from "axios";
import * as constants from "../constants";
import WideList from "../components/common/WideList";
import { Redirect } from "react-router-dom";

class Programs extends Component {
  //    [{coursid: 1, coursename: " Diploma in business management", coursetype: "online",startDate:""},
  // {coursid: 1, coursename: " Diploma in business management", coursetype: "online",startDate:""}]
  // constructor(props) {
  //   this.state = {
  //     title: props.title,
  //     linkUrl: props.link,
  //     programType: props.programType,
  //     listItems: [],
  //   };
  // }
  // _isMounted = false;

  // Following are the states of the this component
  state = {
    cardHeaderSettings: {
      headingText: this.props.title,
      description: this.props.description,
    },
    listData: undefined,
  };
  // The following function will be invoked immediately after this
  //component is mounted an then it will send a get request to server
  // by sending student id and program type in request and a successful
  //response will be passed to parseProgram function.
  async componentDidMount() {
    // this._isMounted = true;
    //console.log("here: " + this.props);
    // let BaseUrl = "https://reqres.in/api/"; //" https://my-json-server.typicode.com/vkkmehra/TLAPI/";
    // let studentId = 1;
    // const { data: listData } = await axios.get(
    //   constants.BASEURL +
    //     "Student/" +
    //     this.props.programType +
    //     "/Programs/" +
    //     studentId
    // );

    //this.setState({ listData });
    // console.log("State Setting: " + listData);
    // await this.parsePrograms(listData);
    //var that = this;
    // return new Promise((resolve, reject) => {
    const config = { headers: { "Content-Type": "application/json" } };
    var studentId = localStorage.getItem("studentId");
    return axios
      .get(
        constants.BASEURL +
          "Student/" +
          this.props.programType +
          "/Programs/" +
          studentId
      )
      .then((response) => {
        // console.log(response.data);
        // this.setState({
        //   listItems:
        // });
        //return responseJson.data;
        //this.setState({ listData: response.data });
        // console.log(
        //   "State Setting: " +
        //     this.state.listData.length +
        //     " = " +
        //     response.data.length
        // );
        this.parsePrograms(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //   fetch(BaseUrl + type, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(userData)
    // })
    //   .then(response => response.json())
    //   .then(responseJson => {
    //     resolve(responseJson);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    //   });
  }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  // componentWillUnmount() {
  //   // fix Warning: Can't perform a React state update on an unmounted component
  //   this.setState = (state, callback) => {
  //     return;
  //   };
  // }
  // Following fuction will process the response data
  parsePrograms(listItem) {
    var that = this;
    // debugger;
    // console.log("in function : " + JSON.stringify(listItem));
    //const { listData } = this.state;

    // Following code will fetch enrolment id, course name, and also will
    // generate a link for target, and course id.
    listItem.map(function (item) {
      item.controlId = item.enrolmentId;
      item.headingText = item.courseName;
      //item.rightLabel = item.courseType;
      item.linkTarget =
        "/dashboard/programs/" +
        item.enrolmentId +
        "/summary?uos=" +
        item.totalUOS +
        "&subjects=" +
        item.totalSubjects;
      item.test = item.courseId;

      // Following code will fetch start and finish dates, completed subjects
      // or Unit of studies
      let itemsList = [];
      var subItem = {};
      subItem.icon =
        "M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z";
      let startText = "Starting on ";
      if (item.started == 1) startText = "Started on ";
      subItem.itemText = startText + item.startDate;
      itemsList.push(subItem);

      subItem = {};
      subItem.icon =
        "M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z";
      let finishText = "Finishing on ";
      if (item.finished == 1) startText = "Finished on ";
      subItem.itemText = "Finishing on " + item.endDate;
      itemsList.push(subItem);

      subItem = {};
      subItem.icon = "";
      let objectText = "Subjects ";
      let totalObjects = item.totalSubjects;
      let completedObjects = item.completedSubjects;
      if (item.totalUOS > 0) {
        objectText = "Units ";
        totalObjects = item.totalUOS;
        completedObjects = item.completedUOS;
      }
      subItem.itemText = "Total " + objectText + totalObjects;
      itemsList.push(subItem);

      subItem = {};
      subItem.icon = "";
      subItem.itemText = "Completed " + objectText + completedObjects;
      itemsList.push(subItem);
      // Followingcode will fetch invoiced amount receipted amount and the date.
      if (parseFloat(item.totalInvoiced) > 0) {
        subItem = {};
        subItem.icon = "";
        subItem.itemText = "Invoiced $" + item.totalInvoiced.toFixed(2);
        itemsList.push(subItem);

        if (parseFloat(item.totalReceipted) > 0) {
          subItem = {};
          subItem.icon = "";
          subItem.itemText = "Receipted $" + item.totalReceipted.toFixed(2);
          itemsList.push(subItem);

          subItem = {};
          subItem.icon = "";
          subItem.itemText =
            "Last Receipt $" + item.lastReceiptAmount.toFixed(2);
          itemsList.push(subItem);

          subItem = {};
          subItem.icon = "";
          subItem.itemText = "on " + item.lastReceiptDate;
          itemsList.push(subItem);
        }
      }

      item.itemList = itemsList;
      //tempList.push(tempItem);
    });

    that.setState({ listData: listItem });
    // console.log(
    //   "List Data: " +
    //     that.state.listData.length +
    //     " = " +
    //     listItem.length +
    //     " = " +
    //     this.state.listData[0].listData[0].itemText
    // );
  }
  // Following code will render the view using WideList component and passing the
  //data we have fetched here
  render() {
    if (!localStorage.getItem("studentId")) {
      return <Redirect to={"/Login"} />;
    }
    if (!this.state.listData) {
      return "loading....";
    }
    return (
      <React.Fragment>
        <WideList
          key={this.props.programType}
          cardHeaderSettings={this.state.cardHeaderSettings}
          listData={this.state.listData}
        ></WideList>
      </React.Fragment>
    );
  }
}

export default Programs;
