import React, { Component } from "react";
import FormLabel from "./FormLabel";
import { Multiselect } from "multiselect-react-dropdown";

class MultiSelection extends Component {
  state = {};
  render() {
    return (
      <div className="">
        <FormLabel name={"Ms1"} text={this.props.label} />
        <div className="mt-1 relative rounded-md shadow-sm ">
          <Multiselect
            id={this.props.id}
            options={this.props.data}
            isObject={false}
            showCheckbox={true}
            selectedValues={this.props.selectedValues}
            onSelect={this.props.onSelect}
            onRemove={this.props.onRemove}
            placeholder={this.props.placeholder}
            avoidHighlightFirstOption={true}
            style={{
              chips: {
                background: "#17a2b8",
                "white-space": "normal",
                "font-weight": "bold",
              },
              searchBox: {
                background: "#fff",
                "padding-top": "0.35rem",
                "padding-bottom": "0.35rem",
              },
              inputField: { width: "5rem" },
            }}
          />
        </div>
      </div>
    );
  }
}

export default MultiSelection;
