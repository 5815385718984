import React, { Component } from 'react'
import FormLabel from "./FormLabel";
import { useState } from "react";

class UploadBox extends Component {
    state = {}
    render() {
        const { label, name, value, onChange, onClick, multi, ref } = this.props
        return (
            <form>
                <div className="col-span-6 sm:col-span-2">
                    <FormLabel text={label} name={name} />
                    <input
                        type="file"
                        onChange={onChange}
                        ref={ref}
                        style={{ display: "none" }}
                    />
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5" onClick={() => this.refs[ref].click()}>
                            {label}
                        </button>
                    </div>
                </div>

            </form>
        );
    }
}


export default UploadBox