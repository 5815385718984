// Firstly we have imported React, then Navbar Component and lastly
// Dashboardboard routes from routes.jsx. In the render Section we
// have created a basic GUI structure for Dashboard and then most of
// the view will be generated by components through dashboardRoutes
// i.e. EnrolmentDetail.jsx, SubjectDetail.jsx, UOSDetail.jsx and
// Program.jsx components.

import React, { Component } from "react";
import NavBar from "./common/NavBar";
import { DashboardRoutes } from "../routes";

class Dashboard extends Component {
  state = {};
  render() {
    return (
      <div id="dash" key="dash" className="max-w-full bg-white">
        <NavBar />
        <div className="py-5">
          {/* WIll be converted to a separate component later */}
          <header>
            {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Dashboard
              </h1>
            </div> */}
          </header>
          <main>
            <DashboardRoutes />
          </main>
        </div>
      </div>
    );
  }
}

export default Dashboard;
