import React, { useState } from "react";
import CardHeader from "../common/CardHeader";


export default function FormSection({ ActionComponent, label, subTitle, children }) {
  const [expanded, setExpanded] = useState([])
  if(label == "VET Student Loans") subTitle = "<span class='text-black'>Go to this link <a target='_blank' class='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href='https://icanlearn.edu.au/vet-student-loans-information'>https://icanlearn.edu.au/vet-student-loans-information/</a> and read all information about VET Student Loans.</span>"
  return (
    <div className="mb-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <div className="px-4 py-4 sm:px-6">

          <CardHeader ActionComponent={ActionComponent} onClick={() => { setExpanded(!expanded) }} expanded={expanded} settings={{ headingText: label, description: subTitle }} />

          <div className={"px-4 py-4 sm:px-6" + (expanded ? "" : " hidden")}>
            <div className="grid grid-cols-6 gap-6">
              {children}
            </div>
          </div>
        </div >
      </div>
    </div>



















  );
}
