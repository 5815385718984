import React, { Component } from "react";
import Loader from "react-loader";
import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, multiselectFilter } from "./common/commonFns";

import { AgentsRequests } from "../apiRequests/AgentsRequests";
import SearchBox from "./controls/SearchBox";
import MultiSelection from "./controls/MultiSelection";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

class PaymentRecordView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
      isLoaded: true,
      agentId: localStorage.getItem("agentId"),
      tableRows: [],
      tableEmpty: "hidden",
      pCommissionsData: [],
      allPrograms: [],
      allEnrolmentNo: [],
      allTransactionNo: [],
      transactionNoSelectedList: [],
      programsSelectedList: [],
      enrolmentNoSelectedList: [],
      firstRowIndex: 0,
      lastRowIndex: constants.NUMBEROFROWS - 1,
      filterOn: false,
      dataLength: 0,
      searchFieldValue: "",
      isOpen: false,
      expanded: "",
      background: "hidden",
      fromDate: "",
      toDate: "",
    };
    this.setPageIndexFn = this.setPageIndexFn.bind(this);
    this.handleDateBox = this.handleDateBox.bind(this);
  }

  componentWillMount() {
    this.setState({
      hidden: "",
      isLoaded: false,
    });
    let startDate = localStorage.getItem("t8StartDate");
    let endDate = localStorage.getItem("t8EndDate");
    this.setState(
      {
        fromDate: startDate,
        toDate: endDate,
      },
      function () {
        this.paidCommissionsFn(startDate, endDate);
      }
    );
  }

  paidCommissionsFn(startDate, endDate) {
    agentsRequests.paymentRecord(startDate, endDate).then((result) => {
      this.setState({ pCommissionsData: result }, function () {
        this.loadMultiSelectData();
      });
    });
  }

  loadMultiSelectData() {
    let allPrograms = [];
    let allTransactionNo = [];
    let allEnrolmentNo = [];

    let data = this.state.pCommissionsData;

    data.map((object) => {
      if (allPrograms.some((item) => object.programName === item)) {
      } else {
        allPrograms.push(object.programName);
      }
    });

    data.map((object) => {
      if (allTransactionNo.some((item) => object.paymentNo === item)) {
      } else {
        allTransactionNo.push(object.paymentNo);
      }
    });

    data.map((object) => {
      if (allEnrolmentNo.some((item) => object.enrolmentNo === item)) {
      } else {
        allEnrolmentNo.push(object.enrolmentNo);
      }
    });

    this.setState(
      {
        allPrograms: allPrograms,
        allTransactionNo: allTransactionNo,
        allEnrolmentNo: allEnrolmentNo,
      },
      function () {
        this.loadData();
      }
    );
  }

  loadData() {
    let tableRows = [];
    let formatedRowData = {};
    let data = this.state.pCommissionsData;
    let firstRowIndex = this.state.firstRowIndex;
    let lastRowIndex = this.state.lastRowIndex;

    data = this.searchItems(data);

    data = this.dataProcessor(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            " Student Number : " + object.studentNo;
          formatedRowData.Label2 = object.programName;
          formatedRowData.DescriptionClm2 =
            "Enrolment Number: " + object.enrolmentNo;
          formatedRowData["Transaction Number"] = object.paymentNo;
          let transactionDate = new Date(object.transactionDate);

          formatedRowData["Transaction Date"] = moment(transactionDate).format(
            "DD-MM-YYYY"
          );
          formatedRowData.Value = "$" + currencyFormat(object.paidAmount);
          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      this.setState({ tableEmpty: "" });
    }

    this.setState({ tableRows: tableRows });

    this.setState({
      hidden: "hidden",
      isLoaded: true,
    });
  }

  dataProcessor(data) {
    let programsSelectedList = this.state.programsSelectedList;
    let transactionNoSelectedList = this.state.transactionNoSelectedList;
    let enrolmentNoSelectedList = this.state.enrolmentNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "programName");
    }
    if (transactionNoSelectedList != "") {
      data = multiselectFilter(data, transactionNoSelectedList, "paymentNo");
    }
    if (enrolmentNoSelectedList != "") {
      data = multiselectFilter(data, enrolmentNoSelectedList, "enrolmentNo");
    }

    // following line of code will set dataLength
    this.setState({ dataLength: data.length });
    return data;
  }

  searchItems(data) {
    let originalData = data;
    let searchValue = this.state.searchFieldValue;
    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
      this.setState({ filterOn: true });
      if (data.length === originalData.length) {
        this.setState({ filterOn: false });
      }
    }

    this.setState({ dataLength: data.length });
    return data;
  }

  renderTable() {
    let tableHeadings = [
      "Student Details",
      "Enrolment Details",
      "Transaction Number",
      "Transaction Date",
      "Paid Amount",
      "",
    ];
    let tableRows = this.state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={this.state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={this.state.dataLength}
          setPageIndexFn={this.setPageIndexFn}
          firstRowIndex={this.state.firstRowIndex}
          lastRowIndex={this.state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            this.state.tableEmpty +
            " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  }

  setPageIndexFn(firstRowIndex, lastRowIndex) {
    this.setState(
      { firstRowIndex: firstRowIndex, lastRowIndex: lastRowIndex },
      function () {
        this.loadData();
      }
    );
  }

  searchHandeler() {
    var searchValue = document.getElementById("search").value;

    this.setState(
      {
        searchFieldValue: searchValue,
      },
      function () {
        this.loadData();
      }
    );
  }

  filtersDropdown() {
    let expanded = this.state.expanded;
    if (expanded) {
      this.setState({
        isOpen: false,
        expanded: "",
        background: "hidden",
      });
    } else {
      this.setState({
        isOpen: true,
        expanded: "yes",
        background: "",
      });
      document.getElementById("search").value = this.state.searchFieldValue;
    }
  }

  handleDateBox(e, name) {
    localStorage.setItem("dateRangeLabel8", "Custom Range");
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(this.state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(this.state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t8StartDate", startDate);
      this.setState(
        {
          fromDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          //console.log(this.state.fromDate);
          this.paidCommissionsFn(startDate, endDate);
        }
      );

      // window.location.reload();
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t8EndDate", endDate);
      this.setState(
        {
          toDate: selectedDate,
          isOpen: false,
          expanded: "",
          background: "hidden",
        },
        function () {
          this.paidCommissionsFn(startDate, endDate);
        }
      );
    }
  }

  onMultiSelectChange(selectedList, item, id) {
    if (id === "program") {
      this.setState({
        programsSelectedList: selectedList,
      });
    } else if (id === "transactionNo") {
      this.setState({
        transactionNoSelectedList: selectedList,
      });
    } else if (id === "enrolmentNo") {
      this.setState({
        enrolmentNoSelectedList: selectedList,
      });
    }
    this.setState(
      {
        isOpen: false,
        expanded: "",
        background: "hidden",
      },
      function () {
        this.loadData();
      }
    );
  }

  renderAdvancedSearch() {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => this.filtersDropdown()}
            className="  p-2 h-7 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={this.state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={this.state.programsSelectedList}
                        data={this.state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="transactionNo"
                        label="Select Transaction Number"
                        selectedValues={this.state.transactionNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "transactionNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "transactionNo"
                          )
                        }
                        data={this.state.allTransactionNo}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="enrolmentNo"
                        label="Select Enrolment Number"
                        selectedValues={this.state.enrolmentNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "enrolmentNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          this.onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "enrolmentNo"
                          )
                        }
                        data={this.state.allEnrolmentNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => this.filtersDropdown()}
            className={
              this.state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="relative mt-6 align-middle min-h-full">
        <span
          className={
            this.state.hidden +
            " fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-25"
          }
        />
        <Loader loaded={this.state.isLoaded}></Loader>

        <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
          <div className="xl:col-span-2">
            <SearchBox
              id={"search"}
              label={"Search For Student Name"}
              svgType={"student"}
              placeholder={"Name"}
              value={this.state.searchFieldValue}
              searchHandler={() => {
                this.searchHandeler();
              }}
            />
          </div>

          <div className="xl:col-span-1">
            <DateBox
              dependentOn={true}
              id={"Td1"}
              label="Transaction Date From"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"FromDate"}
              value={this.state.fromDate ? new Date(this.state.fromDate) : ""}
            />
          </div>
          <div className="xl:col-span-1">
            <DateBox
              dependentOn={true}
              id={"Td1"}
              label="Transaction Date Till"
              //minDate={moment().add(0, "days").toDate()}
              onChange={this.handleDateBox}
              name={"ToDate"}
              value={this.state.toDate ? new Date(this.state.toDate) : ""}
              //onChange={this.handleChange}
            />
          </div>
          <div className="xl:col-span-2">{this.renderAdvancedSearch()}</div>
        </div>

        <div>{this.renderTable()}</div>
      </div>
    );
  }
}

export default PaymentRecordView;
