import React, { Component } from 'react'
import SelectBox from '../controls/SelectBox';
import FormSection from '../controls/FormSection';
import TextBox from './../controls/TextBox';

const blankContact = {
    FName: null,
    LName: null,
    MemberShipEducation: null,
    Position: null,
    QualificationExperience: null,
    error: {}
}

class ContactSection extends Component {
    state = {
        hideActionButton: false,
        currentContact: {},
        contacts: []
    }

    componentWillMount() {
        if (this.state.contacts.length == 0) {
            this.addContact()
        }
    }

    componentDidMount() {
        // this.loadDefaults(this.state.courses)
    }

    addContact() {
        const { program } = this.props
        const cContact = { ...blankContact }
        cContact.error = {}
        const contacts = [...this.state.contacts, cContact]
        // this.loadDefaults(cReference)

        this.setState({ contacts: contacts })
        this.props.onChange(contacts)
    }

    removeContacts(index) {

        var contacts = [...this.state.contacts]
        contacts.splice(index, 1)
        this.setState({ contacts }, this.props.onChange(contacts))

    }

    renderActionButtons = (index) => {
        return (
            <div className="ml-4 mt-2 flex-shrink-0">
                <span className="inline-flex rounded-md shadow-sm mr-2">
                    {index == 0 ? <button type="button" onClick={() => this.addContact()} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">+</button> :
                        <button type="button" onClick={() => this.removeContact(index)} className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">-</button>}
                </span>
            </div>
        )
    }

    handleChange = (e, name) => {
        // if (name) this.state.currentCourse[name] = e
        const { onChange } = this.props
        var currentContact = this.state.currentContact

        let controlName = ""
        if (name) {
            currentContact[name] = e
            controlName = name;
        }
        else {
            currentContact[e.currentTarget.name] = e.currentTarget.value
            controlName = e.currentTarget.name;
        }


        var cContact = { ...currentContact }
        const keys = Object.keys(cContact)
        keys.map(function (key) {
            //  && (value == null || value == 0)
            if (!currentContact) {
                const { currentContact } = this.state
            }
            if (key !== "error") {
                if ((currentContact[key] == null || currentContact[key] == "") && currentContact.error[key] == undefined) return
                if ((currentContact[key] == null || currentContact[key] == "") && currentContact.error[key]) {
                    currentContact.error[key] = true
                    return
                }

                // currentPreference.error[key] = true
            }
        }, this)
        // currentCourse.error = error
        this.setState({ currentContact })
        this.props.onChange(this.state.contacts)
    }

    setCurrentContact(contact) {
        this.setState({ currentContact: contact })
    }
    renderContactFields = (cfield, index, contact) => {
        const { error } = contact
        const { dropDowns: DropDowns } = this.props
        console.log("rendercontactfields" + DropDowns)
        if (cfield.fieldType == 1) {
            return <TextBox name={cfield.fieldName} dependentOn={true} error={error[cfield.fieldName]} label={cfield.fieldLabel} onClick={() => this.setCurrentContact(contact)} value={contact[cfield.fieldName]} onChange={this.handleChange} />
        }
        else {
            return <SelectBox dependentOn={true} name={cfield.fieldName} error={error[cfield.fieldName]} onClick={() => this.setCurrentContact(contact)} label={cfield.fieldLabel + (cfield.isMandatory ? "*" : "")} value={contact[cfield.fieldName]} onChange={this.handleChange} data={DropDowns[cfield.fieldName]} />
        }
    }
    renderContact = (contact, index) => {
        const { contacts, contactFields } = this.props
        let objects = [...contacts]

        return (
            <>
                <div className={"col-span-6 sm:col-span-6 " + (this.state.hideActionButton ? "hidden" : "")} >
                    <div className="flex justify-between items-center flex-wrap sm:flex-no-wrap">
                        {
                            <>
                                <h3 className="text-base leading-6 font-medium text-gray-900">Person {index + 1}</h3>
                                {this.renderActionButtons(index)}
                            </>
                        }
                    </div>
                </div>
                {
                    contactFields.map((cfield, index) => this.renderContactFields(cfield, index, contact))
                    // contactFields.forEach(cfield => {
                    //     console.log(cfield.fieldName)
                    //     if (cfield.fieldType == 1) {
                    //         //     return <TextBox name={cfield.fieldName} dependentOn={true} error={error[cfield.fieldName]} label={cfield.fieldLabel} onClick={() => this.setCurrentContact(contact)} value={contact[cfield.fieldName]} onChange={this.handleChange} />
                    //     }
                    //     else {
                    //         //     return <SelectBox dependentOn={true} name={cfield.fieldName} error={error} onClick={() => this.setCurrentContact(contact)} label={cfield.fieldLabel + (cfield.isMandatory ? "*" : "")} value={contact[cfield.fieldName]} onChange={this.handleChange} data={DropDowns[cfield.fieldName]} />
                    //     }
                    // })
                }
                {/* <TextBox name="InstitutionName" dependentOn={true} error={error.InstituitionName} label="Institution Name" onClick={() => this.setCurrentContact(contact)} value={contact["InstitutionName"]} onChange={this.handleChange} />
                <TextBox name="ContactName" dependentOn={true} error={error.ContactName} label="Contact Name" onClick={() => this.setCurrentContact(contact)} value={contact["ContactName"]} onChange={this.handleChange} />
                <TextBox name="Position" dependentOn={true} error={error.Position} label="Position" onClick={() => this.setCurrentContact(contact)} value={contact["Position"]} onChange={this.handleChange} />
                <TextBox name="ContactNumber" dependentOn={true} error={error.ContactNumber} label="Contact Number" onClick={() => this.setCurrentContact(contact)} value={contact["ContactNumber"]} onChange={this.handleChange} />
                <TextBox name="Email" dependentOn={true} error={error.Email} label="Email" onClick={() => this.setCurrentContact(contact)} value={contact["Email"]} onChange={this.handleChange} /> */}
            </>
        )
    }
    render() {
        const { contacts } = this.state
        console.log(contacts.length + "here")

        return (

            <FormSection FormSection label={this.props.label} >
                { contacts.length == 0 ? this.renderContact({ FName: null, LName: null }, 0) : contacts.map((contact, index) => this.renderContact(contact, index))}
            </FormSection >
        );
    }
}

export default ContactSection;